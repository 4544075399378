<template>
  <div class="pc-course pc-navigation-bar-height min-width">
    <div class="bx">
      <div class="pc-course-title">
        <div class="title">如何在线下智能终端购买数字藏品？</div>
        <div class="text">简单三步即可完成交易</div>
      </div>
      <div class="pc-course-options">
        <div class="item">
          <img src="@/assets/pc/Rectangle 21@2x.png" alt="" />
          <div class="text">第一步</div>
          <div class="title">选中您心仪的数字藏品</div>
          <div class="text2">每次下单只能购买一个数字藏品</div>
        </div>
        <div class="item">
          <img src="@/assets/pc/Rectangle 2 Copy@2x.png" alt="" />
          <div class="text">第二步</div>
          <div class="title">输入手机号码并验证</div>
          <div class="text2">
            手机号码是购买数字藏品的凭证，购买成功后会收到短信
          </div>
        </div>
        <div class="item">
          <img src="@/assets/pc/Group 72@2x.png" alt="" />
          <div class="text">第三步</div>
          <div class="title">扫码支付，数字藏品购买成功</div>
          <div class="text2">支持微信/支付宝/Visa/Master等支付方式</div>
        </div>
      </div>
      <div class="pc-course-examine-title">如何查看我购买的数字藏品？</div>
      <div class="pc-course-examine-content">
        <div class="title">在商城登入个人中心进行查看</div>
        <div class="text">
          已经购买数字藏品的用户，可以在商城查看个人的数字藏品
        </div>
        <el-button
          @click="$router.push('/market')"
          class="btn"
          type="danger"
          round
          >去商城</el-button
        >
      </div>
      <div class="pc-course-extract-title">如何提取我的数字藏品？</div>
      <div class="pc-course-extract-content">
        <div class="item">
          <img src="@/assets/pc/Rectangle Copy 72@2x.png" alt="">
          <div class="right">
            <div class="text1">第一步</div>
            <div class="text2">填入跟数字藏品相同网络的地址</div>
            <div class="text3">相同网络的提取地址非常重要，如果填错，将会对数字藏品资产造成不可挽回的损失，在提取的时候，请用户务必仔细核对网络和地址的正确性</div>
          </div>
        </div>
        <div class="item">
          <img src="@/assets/pc/Rectangle Copy 83@2x.png" alt="">
          <div class="right">
            <div class="text1">第二步</div>
            <div class="text2">提交申请，进行短信验证</div>
            <div class="text3">为了保证资产安全，数字藏品的每次提取，我们都需要对用户手机号码进行短信验证，请保证手机能正常通信</div>
          </div>
        </div>
        <div class="item">
          <img src="@/assets/pc/Group 82@2x.png" alt="">
          <div class="right">
            <div class="text1">第三步</div>
            <div class="text2">平台审核，提取成功</div>
            <div class="text3">平台会对用户的提取请求进行审核，<br>
通过后，会把数字藏品转到用户的提取地址</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.pc-course {
  .bx {
    width: 12.4rem;
    box-sizing: border-box;
    margin: 0 auto;
    padding-top: 1.07rem;
    .pc-course-title {
      font-size: 0.19rem;
      text-align: center;
      margin-bottom: 0.4rem;
      .title {
        font-size: 0.28rem;
        font-weight: 900;
        margin-bottom: 0.11rem;
      }
    }
    .pc-course-options {
      width: 100%;
      height: 4.4rem;
      background-color: #fff;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      font-size: 0.19rem;
      color: #3f3f3f;
      padding-left: 1.61rem;
      padding-right: 1.17rem;
      padding-top: 0.53rem;
      margin-bottom: 0.91rem;
      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 2rem;
          height: 2rem;
          margin-bottom: 0.26rem;
        }
        .text {
          margin-bottom: 0.05rem;
        }
        .title {
          font-size: 0.24rem;
          font-weight: 600;
          color: #3f3f3f;
          margin-bottom: 0.08rem;
        }
        .text2 {
          font-size: 0.16rem;
          color: #7f7f7f;
          width: 2.53rem;
          text-align: center;
        }
      }
    }
    .pc-course-examine-title {
      font-size: 0.28rem;
      font-weight: 900;
      text-align: center;
      margin-bottom: 0.4rem;
    }
    .pc-course-examine-content {
      border-radius: .15rem;
      background: url('../../assets/pc/Group 32@2x.png') no-repeat;
      background-size: 100% 100%;
      width: 100%;
      height: 3.13rem;
      position: relative;
      margin-bottom: .93rem;
      .title {
        position: absolute;
        top: 0.88rem;
        left: 0.36rem;
        font-size: 0.27rem;
      }
      .text {
        color: #3f3f3f;
        font-size: 0.19rem;
        position: absolute;
        top: 1.31rem;
        left: 0.36rem;
      }
      .btn {
        width: 1.73rem;
        height: 0.64rem;
        background: url('../../assets/pc/Group 11@2x.png') no-repeat;
        background-size: 100% 100%;
        font-size: 0.21rem;
        font-weight: 400;
        color: #ffffff;
        position: absolute;
        right: 1.07rem;
        bottom: 0.52rem;
        border-radius: 0.35rem;
      }
    }
    .pc-course-extract-title {
      font-size: .28rem;
      text-align: center;
font-weight: 900;
margin-bottom: .4rem;
    }
    .pc-course-extract-content {
      border-radius: .15rem;
      width: 100%;
      height: 10.96rem;
      box-sizing: border;
      background: url('../../assets/pc/Group 103@2x.png') no-repeat;
      background-size: 100% 100%;
      margin-bottom: 1rem;
      padding: .8rem 2.09rem 1.07rem 1.73rem;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .item {
        display: flex;
        font-size: .19rem;
        img {
          width: 2rem;
          height: 2rem;
          margin-right: .29rem;
        }
        .right {
          .text1 {
          color: #3F3F3F;
          margin-bottom: .04rem;
        }
        .text2 {
          font-size: .24rem;
font-weight: 600;
color: #3F3F3F;
          margin-bottom: .09rem;
        }
        .text3 {
          color: #3F3F3F;
line-height: 27px;
        }
        }
      }
    }
  }
}
</style>
