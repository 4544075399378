<template>
  <div>
    <pc-navigation-bar v-if="!$isMobile"></pc-navigation-bar>
    <m-navigation-bar v-else></m-navigation-bar>
      <p-industry v-if="!$isMobile"></p-industry>
      <m-industry v-else></m-industry>
      <pc-footer v-if="!$isMobile"></pc-footer>
    <m-footer v-else></m-footer>
  </div>
</template>

<script>
import PIndustry from '@/components/pc/industry.vue'
import MIndustry from '@/components/mobile/industry.vue'
export default {
  components: {
    PIndustry,
    MIndustry,
  }
}
</script>

<style>

</style>