<template>
  <div>
    <pc-navigation-bar v-if="!$isMobile"></pc-navigation-bar>
      <p-pay v-if="!$isMobile"></p-pay>
      <pc-footer v-if="!$isMobile"></pc-footer>
  </div>
</template>

<script>
import PPay from '@/components/pc/pay.vue'
export default {
  components: {
    PPay,
  }
}
</script>

<style>

</style>