<template>
  <div>
    <pc-navigation-bar v-if="!$isMobile"></pc-navigation-bar>
    <m-navigation-bar v-else></m-navigation-bar>
    <p-market v-if="!$isMobile"></p-market>
    <m-market v-else></m-market>
    <pc-footer v-if="!$isMobile"></pc-footer>
    <m-footer v-else></m-footer>
  </div>
</template>

<script>
import PMarket from '@/components/pc/market.vue'
import MMarket from '@/components/mobile/market.vue'
export default {
  components: {
    PMarket,
    MMarket
  }
}
</script>

<style>

</style>