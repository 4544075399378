export function dataInJs() {
	var phoneCode = [
        ["+86", "China", "中国", "中國"],
        ["+852", "Hong Kong", "中国香港", "中國香港"],
        ["+376", "Andorra", "安道尔", "安道爾"],
        ["+971", "United Arab Emirates", "阿拉伯联合酋长国", "阿拉伯聯合酋長國"],
        ["+93", "Afghanistan", "阿富汗", "阿富汗"],
        ["+1268", "Antigua and Barbuda", "安提瓜和巴布达", "安提瓜和巴布達"],
        ["+1264", "Anguilla", "安圭拉", "安圭拉"],
        ["+355", "Albania", "阿尔巴尼亚", "阿爾巴尼亞"],
        ["+374", "Armenia", "亚美尼亚", "亞美尼亞"],
        ["+599", "Netherlands Antilles", "荷属安的列斯群岛", "荷屬安的列斯群島"],
        ["+244", "Angola", "安哥拉", "安哥拉"],
        ["+672", "Antarctica", "南极", "南極"],
        ["+54", "Argentina", "阿根廷", "阿根廷"],
        ["+1684", "American Samoa", "美属萨摩亚", "美屬薩摩亞"],
        ["+43", "Austria", "奥地利", "奧地利"],
        ["+61", "Australia", "澳大利亚", "澳大利亞"],
        ["+297", "Aruba", "阿鲁巴", "阿魯巴"],
        ["+994", "Azerbaijan", "阿塞拜疆", "阿塞拜疆"],
        ["+387", "Bosnia and Herzegovina", "波斯尼亚和黑塞哥维那", "波斯尼亞和黑塞哥維那"],
        ["+1246", "Barbados", "巴巴多斯", "巴巴多斯"],
        ["+880", "Bangladesh", "孟加拉国", "孟加拉國"],
        ["+32", "Belgium", "比利时", "比利時"],
        ["+226", "Burkina Faso", "布基纳法索", "布基納法索"],
        ["+359", "Bulgaria", "保加利亚", "保加利亞"],
        ["+973", "Bahrain", "巴林", "巴林"],
        ["+257", "Burundi", "蒲隆地", "蒲隆地"],
        ["+229", "Benin", "贝宁", "貝寧"],
        ["+590", "Saint Barthelemy", "圣巴泰勒米", "聖巴泰勒米"],
        ["+1441", "Bermuda", "百慕大", "百慕大"],
        ["+673", "Brunei", "文莱", "文萊"],
        ["+591", "Bolivia", "玻利维亚", "玻利維亞"],
        ["+55", "Brazil", "巴西", "巴西"],
        ["+1242", "Bahamas", "巴哈马", "巴哈馬"],
        ["+975", "Bhutan", "不丹", "不丹"],
        ["+267", "Botswana", "波扎那", "波扎那"],
        ["+375", "Belarus", "白俄罗斯", "白俄羅斯"],
        ["+501", "Belize", "伯利兹", "伯利茲"],
        ["+1", "Canada", "加拿大", "加拿大"],
        ["+61", "Cocos Islands", "科科斯群岛", "科科斯群島"],
        ["+243", "Democratic Republic of the Congo", "刚果民主共和国", "剛果民主共和國"],
        ["+36", "Central African Republic", "中非共和国", "中非共和國"],
        ["+242", "Republic of the Congo", "刚果共和国", "剛果共和國"],
        ["+41", "Switzerland", "瑞士", "瑞士"],
        ["+225", "Ivory Coast", "象牙海岸", "象牙海岸"],
        ["+682", "Cook Islands", "库克群岛", "庫克群島"],
        ["+56", "Chile", "智利", "智利"],
        ["+237", "Cameroon", "喀麦隆", "喀麥隆"],
        ["+57", "Colombia", "哥伦比亚", "哥倫比亞"],
        ["+506", "Costa Rica", "哥斯达黎加", "哥斯達黎加"],
        ["+53", "Cuba", "古巴", "古巴"],
        ["+238", "Cape Verde", "佛得角", "佛得角"],
        ["+599", "Curacao", "库拉索", "庫拉索"],
        ["+61", "Christmas Island", "圣诞岛", "聖誕島"],
        ["+357", "Cyprus", "塞浦路斯", "塞浦路斯"],
        ["+420", "Czech Republic", "捷克共和国", "捷克共和國"],
        ["+49", "Germany", "德国", "德國"],
        ["+253", "Djibouti", "吉布", "吉布"],
        ["+45", "Denmark", "丹麦", "丹麥"],
        ["+1767", "Dominica", "多米尼加", "多米尼加"],
        ["+1809", "Dominican Republic", "多米尼加共和国", "多米尼加共和國"],
        ["+213", "Algeria", "阿尔及利亚", "阿爾及利亞"],
        ["+593", "Ecuador", "厄瓜多尔", "厄瓜多爾"],
        ["+372", "Estonia", "爱沙尼亚", "愛沙尼亞"],
        ["+20", "Egypt", "埃及", "埃及"],
        ["+212", "Western Sahara", "西撒哈拉", "西撒哈拉"],
        ["+291", "Eritrea", "厄利垂亚", "厄利垂亞"],
        ["+34", "Spain", "西班牙", "西班牙"],
        ["+251", "Ethiopia", "埃塞俄比亚", "埃塞俄比亞"],
        ["+358", "Finland", "芬兰", "芬蘭"],
        ["+679", "Fiji", "斐济", "斐濟"],
        ["+500", "Falkland Islands", "福克兰群岛", "福克蘭群島"],
        ["+691", "Micronesia", "密克罗尼西亚联邦", "密克罗尼西亚联邦"],
        ["+298", "Faroe Islands", "法罗群岛", "法羅群島"],
        ["+33", "France", "法国", "法國"],
        ["+241", "Gabon", "加蓬", "加蓬"],
        ["+44", "United Kingdom", "英国", "英國"],
        ["+1473", "Grenada", "格林纳达", "格林納達"],
        ["+995", "Georgia", "乔治亚", "喬治亞"],
        ["+1437", "Guernsey", "根西岛", "根西島"],
        ["+233", "Ghana", "加纳", "加納"],
        ["+350", "Gibraltar", "直布罗陀", "直布羅陀"],
        ["+299", "Greenland", "格陵兰", "格陵蘭"],
        ["+220", "Gambia", "冈比亚", "岡比亞"],
        ["+224", "Guinea", "几内亚", "幾內亞"],
        ["+240", "Equatorial Guinea", "赤道几内亚", "赤道幾內亞"],
        ["+30", "Greece", "希腊", "希臘"],
        ["+502", "Guatemala", "危地马拉", "危地馬拉"],
        ["+1671", "Guam", "关岛", "關島"],
        ["+245", "Guinea-Bissau", "几内亚比绍", "幾內亞比紹"],
        ["+592", "Guyana", "圭亚那", "圭亞那"],
        ["+504", "Honduras", "洪都拉斯", "洪都拉斯"],
        ["+385", "Croatia", "克罗地亚", "克羅地亞"],
        ["+509", "Haiti", "海地", "海地"],
        ["+36", "Hungary", "匈牙利", "匈牙利"],
        ["+62", "Indonesia", "印度尼西亚", "印度尼西亞"],
        ["+353", "Ireland", "爱尔兰", "愛爾蘭"],
        ["+972", "Israel", "以色列", "以色列"],
        ["+1580", "Isle of Man", "马恩岛", "馬恩島"],
        ["+91", "India", "印度", "印度"],
        ["+246", "British Indian Ocean Territory", "英属印度洋领地", "英屬印度洋領地"],
        ["+964", "Iraq", "伊拉克", "伊拉克"],
        ["+98", "Iran", "伊朗", "伊朗"],
        ["+354", "Iceland", "冰岛", "冰島"],
        ["+39", "Italy", "意大利", "意大利"],
        ["+1490", "Jersey", "泽西", "澤西"],
        ["+1876", "Jamaica", "牙买加", "牙買加"],
        ["+962", "Jordan", "约旦", "約旦"],
        ["+81", "Japan", "日本", "日本"],
        ["+254", "Kenya", "肯尼亚", "肯尼亞"],
        ["+996", "Kyrgyzstan", "吉尔吉斯斯坦", "吉爾吉斯斯坦"],
        ["+855", "Cambodia", "柬埔寨", "柬埔寨"],
        ["+686", "Kiribati", "基里巴斯", "基里巴斯"],
        ["+269", "Comoros", "科摩罗", "科摩羅"],
        ["+1869", "Saint Kitts and Nevis", "圣基茨和尼维斯", "聖基茨和尼維斯"],
        ["+850", "North Korea", "朝鲜", "朝鮮"],
        ["+82", "South Korea", "韩国", "韓國"],
        ["+965", "Kuwait", "科威特", "科威特"],
        ["+1345", "Cayman Islands", "开曼群岛", "開曼群島"],
        ["+7", "Kazakhstan", "哈萨克", "哈薩克"],
        ["+856", "Laos", "寮国", "寮國"],
        ["+961", "Lebanon", "黎巴嫩", "黎巴嫩"],
        ["+1758", "Saint Lucia", "圣卢西亚", "聖盧西亞"],
        ["+423", "Liechtenstein", "列支敦士登", "列支敦士登"],
        ["+94", "Sri Lanka", "斯里兰卡", "斯里蘭卡"],
        ["+231", "Liberia", "利比里亚", "利比里亞"],
        ["+266", "Lesotho", "莱索托", "萊索托"],
        ["+370", "Lithuania", "立陶宛", "立陶宛"],
        ["+352", "Luxembourg", "卢森堡", "盧森堡"],
        ["+371", "Latvia", "拉脱维亚", "拉脫維亞"],
        ["+218", "Libya", "利比亚", "利比亞"],
        ["+212", "Morocco", "摩洛哥", "摩洛哥"],
        ["+377", "Monaco", "摩纳哥", "摩納哥"],
        ["+373", "Moldova", "摩尔多瓦", "摩爾多瓦"],
        ["+382", "Montenegro", "黑山", "黑山"],
        ["+590", "Saint Martin", "圣马丁", "聖馬丁"],
        ["+261", "Madagascar", "马达加斯加", "馬達加斯加"],
        ["+692", "Marshall Islands", "马歇尔群岛", "馬歇爾群島"],
        ["+389", "Macedonia", "马其顿", "馬其頓"],
        ["+223", "Mali", "马里", "馬里"],
        ["+95", "Myanmar", "缅甸", "緬甸"],
        ["+976", "Mongolia", "蒙古国", "蒙古國"],
        ["+853", "Macau", "中国澳门", "中國澳門"],
        ["+1670", "Northern Mariana Islands", "北马里亚纳群岛", "北馬里亞納群島"],
        ["+222", "Mauritania", "茅利塔尼亚", "茅利塔尼亞"],
        ["+1664", "Montserrat", "蒙塞拉特岛", "蒙塞拉特島"],
        ["+356", "Malta", "马耳他", "馬耳他"],
        ["+230", "Mauritius", "毛里求斯", "毛里求斯"],
        ["+960", "Maldives", "马尔代夫", "馬爾代夫"],
        ["+265", "Malawi", "马拉维", "馬拉維"],
        ["+52", "Mexico", "墨西哥", "墨西哥"],
        ["+60", "Malaysia", "马来西亚", "馬來西亞"],
        ["+258", "Mozambique", "莫桑比克", "莫桑比克"],
        ["+264", "Namibia", "纳米比亚", "納米比亞"],
        ["+687", "New Caledonia", "新喀裡多尼亚", "新喀裡多尼亞"],
        ["+227", "Niger", "尼日尔", "尼日爾"],
        ["+234", "Nigeria", "尼日利亚", "尼日利亞"],
        ["+505", "Nicaragua", "尼加拉瓜", "尼加拉瓜"],
        ["+31", "Netherlands", "荷兰", "荷蘭"],
        ["+47", "Norway", "挪威", "挪威"],
        ["+977", "Nepal", "尼泊尔", "尼泊爾"],
        ["+674", "Nauru", "瑙鲁", "瑙魯"],
        ["+683", "Niue", "纽埃", "紐埃"],
        ["+64", "New Zealand", "新西兰", "新西蘭"],
        ["+968", "Oman", "阿曼", "阿曼"],
        ["+507", "Panama", "巴拿马", "巴拿馬"],
        ["+51", "Peru", "秘鲁", "秘魯"],
        ["+689", "French Polynesia", "法属波利尼西亚", "法屬波利尼西亞"],
        ["+675", "Papua New Guinea", "巴布亚新几内亚", "巴布亞新幾內亞"],
        ["+63", "Philippines", "菲律宾", "菲律賓"],
        ["+92", "Pakistan", "巴基斯坦", "巴基斯坦"],
        ["+48", "Poland", "波兰", "波蘭"],
        ["+508", "Saint Pierre and Miquelon", "圣皮埃尔和密克隆", "聖皮埃爾和密克隆"],
        ["+64", "Pitcairn", "皮特凯恩", "皮特凱恩"],
        ["+1787", "Puerto Rico", "波多黎各", "波多黎各"],
        ["+970", "Palestine", "巴勒斯坦", "巴勒斯坦"],
        ["+351", "Portugal", "葡萄牙", "葡萄牙"],
        ["+680", "Palau", "帕劳", "帕勞"],
        ["+595", "Paraguay", "巴拉圭", "巴拉圭"],
        ["+974", "Qatar", "卡塔尔", "卡塔爾"],
        ["+262", "Reunion", "团聚", "團聚"],
        ["+40", "Romania", "罗马尼亚", "羅馬尼亞"],
        ["+381", "Serbia", "塞尔维亚", "塞爾維亞"],
        ["+7", "Russia", "俄罗斯", "俄羅斯"],
        ["+250", "Rwanda", "卢旺达", "盧旺達"],
        ["+966", "Saudi Arabia", "沙特阿拉伯", "沙特阿拉伯"],
        ["+677", "Solomon Islands", "所罗门群岛", "所羅門群島"],
        ["+248", "Seychelles", "塞舌耳", "塞舌耳"],
        ["+249", "Sudan", "苏丹", "蘇丹"],
        ["+46", "Sweden", "瑞典", "瑞典"],
        ["+65", "Singapore", "新加坡", "新加坡"],
        ["+290", "Saint Helena", "圣赫勒拿", "聖赫勒拿"],
        ["+386", "Slovenia", "斯洛文尼亚", "斯洛文尼亞"],
        ["+47", "Svalbard and Jan Mayen", "斯瓦巴和扬马延", "斯瓦巴和揚馬延"],
        ["+421", "Slovakia", "斯洛伐克", "斯洛伐克"],
        ["+232", "Sierra Leone", "塞拉利昂", "塞拉利昂"],
        ["+378", "San Marino", "圣马力诺", "聖馬力諾"],
        ["+221", "Senegal", "塞内加尔", "塞內加爾"],
        ["+252", "Somalia", "索马里", "索馬里"],
        ["+597", "Suriname", "苏里南", "蘇里南"],
        ["+211", "South Sudan", "南苏丹", "南蘇丹"],
        ["+239", "Sao Tome and Principe", "圣多美和普林西比", "聖多美和普林西比"],
        ["+503", "El Salvador", "萨尔瓦多", "薩爾瓦多"],
        ["+1721", "Sint Maarten", "荷属圣马丁", "荷屬聖馬丁"],
        ["+963", "Syria", "叙利亚", "敘利亞"],
        ["+268", "Swaziland", "史瓦济兰", "史瓦濟蘭"],
        ["+1649", "Turks and Caicos Islands", "特克斯和凯科斯群岛", "特克斯和凱科斯群島"],
        ["+235", "Chad", "查德", "查德"],
        ["+228", "Togo", "多哥", "多哥"],
        ["+66", "Thailand", "泰国", "泰國"],
        ["+992", "Tajikistan", "塔吉克", "塔吉克"],
        ["+690", "Tokelau", "托克劳", "托克勞"],
        ["+670", "East Timor", "东帝汶", "東帝汶"],
        ["+993", "Turkmenistan", "土库曼斯坦", "土庫曼斯坦"],
        ["+216", "Tunisia", "突尼斯", "突尼斯"],
        ["+676", "Tonga", "汤加", "湯加"],
        ["+90", "Turkey", "土耳其", "土耳其"],
        ["+1868", "Trinidad and Tobago", "特立尼达和多巴哥", "特立尼達和多巴哥"],
        ["+688", "Tuvalu", "图瓦卢", "圖瓦盧"],
        ["+886", "Taiwan", "中国台湾", "中國台灣"],
        ["+255", "Tanzania", "坦桑尼亚", "坦桑尼亞"],
        ["+380", "Ukraine", "乌克兰", "烏克蘭"],
        ["+256", "Uganda", "乌干达", "烏干達"],
        ["+1", "United States", "美国", "美國"],
        ["+598", "Uruguay", "乌拉圭", "烏拉圭"],
        ["+998", "Uzbekistan", "乌兹别克", "烏茲別克"],
        ["+379", "Vatican", "梵蒂冈", "梵蒂岡"],
        ["+1784", "Saint Vincent and the Grenadines", "圣文森特和格林纳丁斯", "聖文森特和格林納丁斯"],
        ["+58", "Venezuela", "委内瑞拉", "委內瑞拉"],
        ["+1284", "British Virgin Islands", "英属维尔京群岛", "英屬維爾京群島"],
        ["+1340", "U.S. Virgin Islands", "美属维尔京群岛", "美屬維爾京群島"],
        ["+84", "Vietnam", "越南", "越南"],
        ["+678", "Vanuatu", "万那杜共和国", "萬那杜共和國"],
        ["+681", "Wallis and Futuna", "瓦利斯和富图纳", "瓦利斯和富圖納"],
        ["+685", "Samoa", "萨摩亚", "薩摩亞"],
        ["+383", "Kosovo", "科索沃", "科索沃"],
        ["+967", "Yemen", "叶门", "葉門"],
        ["+262", "Mayotte", "马约特", "馬約特"],
        ["+27", "South Africa", "南非", "南非"],
        ["+260", "Zambia", "尚比亚", "尚比亞"],
        ["+263", "Zimbabwe", "津巴布韦", "津巴布韋"]
        ];
        let twArr = []
  let zhArr = []
  let enArr = []
  const newPhoneArr = {}
  phoneCode.forEach(item => {
    twArr.push({ label: item[0], value: item[3] })
    zhArr.push({ label: item[0], value: item[2] })
    enArr.push({ label: item[0], value: item[1] })
    })
    newPhoneArr.twArr = twArr
    newPhoneArr.zhArr = zhArr
    newPhoneArr.enArr = enArr
    
	return localStorage.getItem('changeLang') == 'en' ?  newPhoneArr.enArr : (localStorage.getItem('changeLang') == 'tw' ?  newPhoneArr.twArr : newPhoneArr.zhArr);
}
