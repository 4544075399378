<template>
  <div class="my">
    <div class="head">
      <div class="title">
        <p>{{ $t('loginBox.introduce1') }}</p>
        <p>{{ $t('loginBox.introduce2') }}</p>
      </div>
      <div class="form">
        <div class="input">
          <!-- <el-dropdown size="small" trigger="click" placement="bottom-start">
            <span class="el-dropdown-link" style="cursor: pointer;">
              {{ value }}
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="value in areaCode" :key="value.value">{{ value.value }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown> -->
          <el-select class="prefix" v-model="value">
            <div class="prefixSearch">
              <el-input v-model="prefixSearch" prefix-icon="el-icon-search" @input="inputSearch" :placeholder="$t('loginBox.search')"></el-input>
            </div>
            <el-option
              v-for="item in list"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            >
            <span style="float: left">{{ item.label }}</span>
            <span style="float: right; color: #8492a6; font-size: .13rem">{{ item.value }}</span>
              <!-- <span>{{ item.label }}</span>
              <span>{{ item.value }}</span> -->
            </el-option>
          </el-select>
          <div class="wire"></div>
          <el-input
            class="right_input"
            v-model.trim="formData.phone"
            :placeholder="$t('loginBox.inputPhone')"
            type="text"
            oninput="value=value.replace(/[^\d.]/g,'')"
            @blur="salaryChange"
          ></el-input>
        </div>
        <div class="input inputCode" style="margin-top: .18rem;" slot="footer">
          <el-input
            :style="{ width: phoneCode ? '2.35rem' : '' }"
            v-model.trim="formData.code"
            :placeholder="$t('loginBox.inputCode')"
            type="text"
            oninput="value=value.replace(/[^\d.]/g,'')"
            @blur="salaryChange2"
          ></el-input>
          <el-button v-show="!phoneCode" class="btn" @click="GetCodeBtn"
            >{{ $t('loginBox.List') }}</el-button>
          <span v-show="phoneCode" class="phoneCode" @click="NoCodeBtn">{{
            phoneCode + $t('loginBox.codeList')
          }}</span>
        </div>
      </div>
      <div class="login">
        <div class="agreement">
          <el-checkbox v-model="checked"></el-checkbox>
          <p>{{ $t('loginBox.read') }}<span @click="privacyDialog($t('loginBox.userAgreement'))" style="color: #FF3030;cursor: pointer;">{{ '《' + $t('loginBox.userAgreement') + '》' }}</span>{{ $t('loginBox.and') }}<span @click="privacyDialog($t('loginBox.privacyPolicy'))" style="color: #FF3030;cursor: pointer;">{{ '《' + $t('loginBox.privacyPolicy') + '》' }}</span></p>
        </div>
        <el-button class="btn" @click="loginBtn">{{ $t('loginBox.login') }}</el-button>
      </div>
    </div>
    <p class="out" @click="outBtn">{{ $t('loginBox.cancel') }}</p>
    <new-dialog :isShowDialog.sync="isShowDialog" :title.sync="title" @showDialog="isShowDialog = false"></new-dialog>
  </div>
</template>

<script>
import { checkPhone } from '@/common/common.js'
import { dataInJs } from '@/common//phoneArea.js'
import newDialog from '@/components/pc/dialog.vue'
import { TencentCode, getCode, login } from '@/common/api.js'
import '@/common/tencent/TCaptcha.js'
import { mapState, mapMutations } from 'vuex'
export default {
  components: {
    newDialog
  },
  data() {
    return {
      isShowDialog: false,
      formData: {
        phone: '',
        code: '',
      },
      prefixSearch: '',
      checked: false,
      value: '+86', // 手机前缀
      phoneCode: 0,
      timer: null,
      title: '',
      list: dataInJs(),
      // list: this.language == 'en' ? dataInJs().enArr : (this.language == 'zh-CN' ? dataInJs().zhArr : dataInJs().twArr),
    }
  },
  computed: {
      // list:{
        // list() {
        //   let arr = this.language == 'en' ? dataInJs().enArr : (this.language == 'zh-CN' ? dataInJs().zhArr : dataInJs().twArr)
        // return arr
        // },
      // }
    },
    watch: {
    },
  mounted() {
  },
  methods: {
    ...mapMutations(['setTokenInfo', 'setLoginShow']),
    privacyDialog(title) {
      this.isShowDialog = true
      this.title = title
      // console.log(title)
      // console.log(this.$t('userAgreement.userAgreement'))
      // if(title == this.$t('userAgreement.userAgreement')) {
      //   console.log(11111111)
      // } else {
      //   console.log(222222222222222220)
      // }
      // console.log(title === 'Terms and Conditions')
    },
    GetCodeBtn() {
      var Captcha = new TencentCaptcha('2009916787', async(res) => {
        // 返回结果
        // ret         Int       验证结果，0：验证成功。2：用户主动关闭验证码。
        // ticket      String    验证成功的票据，当且仅当 ret = 0 时 ticket 有值。
        // CaptchaAppId       String    验证码应用ID。
        // bizState    Any       自定义透传参数。
        // randstr     String    本次验证的随机串，请求后台接口时需带上。
        if (res.ret == 0) {
          //     TencentCode({ randstr: res.randstr, ticket: res.ticket }).then(res => {
          //   console.log(res, '这里')
          // }).catch(err => {
          //   console.log(err, '错误')
          // })
          const res2 = await TencentCode({
            randstr: res.randstr,
            ticket: res.ticket,
            permission: 'no',
          })
          if (res2.data.unlockLogin) {
            const res3 = await getCode({
              mobile: this.value + this.formData.phone,
              permission: 'no',
            })
            if (res3.data.countDown) {
              this.$message.success(this.$t('message.sendOk'))
              this.phoneCode = res3.data.countDown
              this.timer = setInterval(() => {
                if (res3.data.countDown > 0 && this.phoneCode > 0) {
                  this.phoneCode--
                } else {
                  clearInterval(this.timer)
                  this.phoneCode = 0
                }
              }, 1000)
            }
          }
        } else {
          this.$notify.warning(this.$t('message.verification'))
          // console.log(res)
        }
      })
      if (this.formData.phone && checkPhone(this.formData.phone)) {
        Captcha.show()
        var title = document.getElementById('guideText')
      } else if (!this.formData.phone) {
        this.$message.error(this.$t('message.inputPhone'))
      } else if (!checkPhone(this.formData.phone)) {
        this.$message.error(this.$t('message.inputPhoneOk'))
      }
    },
    NoCodeBtn() {
      this.$message.error(this.$t('message.oftenNo'))
    },
    async loginBtn() {
      if (
        this.formData.code &&
        checkPhone(this.formData.phone) &&
        this.checked
      ) {
        const res = await login({
          code: this.formData.code,
          mobile: this.formData.phone,
          mobilePrefix: this.value,
          permission: 'no',
        })
        let userInfo = res.data.user
        userInfo.token = res.data.token
        this.setTokenInfo(userInfo)
        this.$message.success(this.$t('message.loginOk'))
        this.formData.phone = ''
        this.formData.code = ''
        this.checked = false
        this.value = '+86'
        // this.setLoginShow(false)
        // this.$emit('updateMarketList')
        this.setLoginShow(false)
      } else if (!this.formData.phone) {
        this.$message.error(this.$t('message.inputPhone'))
      } else if (!checkPhone(this.formData.phone)) {
        this.$message.error(this.$t('message.inputPhoneOk'))
      } else if (!this.formData.code) {
        this.$message.error(this.$t('message.inputCode'))
      } else if (this.formData.code.length > 6) {
        this.$message.error(this.$t('message.inputCodeOk'))
      } else if (!this.checked) {
        this.$message.error(this.$t('message.readAgreement'))
      }
    },
    outBtn() {
      this.setLoginShow(false)
      this.formData = {
        phone: '',
        code: '',
      }
    },

    salaryChange(e) {
      this.formData.phone = e.target.value
    },
    salaryChange2(e) {
      this.formData.code = e.target.value
    },
    inputSearch() {
      let newArr = []
      let arr = dataInJs()
      for (var i = 0; i < arr.length; i++) {
      if (arr[i].label.indexOf(this.prefixSearch) >= 0) {
        newArr.push(arr[i]);
      }
    }
    this.list = newArr
    },
  },
}
</script>

<style lang="less" scoped>
  .my {
  background: url(../../assets/pc/Group2.png) no-repeat;
  box-sizing: border-box;
  background-size: 100%;
  width: 5.63rem;
  height: 6.12rem;
  padding: .74rem .8rem .35rem .8rem;
  position: relative;
  margin-left: -3rem;
  z-index: 9;
  .head {
    .title {
      box-sizing: border-box;
      width: 4.68rem;
      height: .66rem;
      font-size: .2rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: .33rem;
    }
  }

  /deep/ .form {
    margin-top: .36rem;
    margin-bottom: 1rem;

    .input {
      box-sizing: border-box;
      width: 4.03rem;
      height: .68rem;
      border-radius: .34rem;
      border: .01rem solid #5C5C5C;
      font-size: .18rem;
      padding-left: .28rem;
      padding-right: .2rem;
      display: flex;
      align-items: center;

      .wire {
        width: .01rem;
        height: .33rem;
        background-color: #505050;
        margin-right: .17rem;
      }

      .prefix {
        position: relative;
        width: .9rem;

        .el-input__inner {
          padding-right: 0;
          background-color: #f9f9f9;
          background-image: none;
        }

        .el-input__suffix {
          display: none;
        }
      }
      .el-input__inner {
        font-size: .18rem;
        border: none;
        padding-left: 0;
        background-image: linear-gradient(to right, #f9f9f9, #F2F2F2);
      }

      .btn {
        width: .74rem;
        height: .41rem;
        background-color: #FF3030;
        border-radius: .19rem;
        font-size: .18rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .phoneCode {
        width: 1.5rem;
        height: .2rem;
        font-size: .14rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: .2rem;
        text-align: right;
      }
    }
  }

  .login {
    display: flex;
    flex-direction: column;
    align-items: center;

    /deep/ .agreement {
      display: flex;
      // align-items: center;

      p {
        font-size: .14rem;
        display: flex;
        align-items: center;
      }
      .el-checkbox {
        margin-right: .05rem;
      }
      .el-checkbox__inner {
        border-radius: 50%;
        width: .18rem;
        height: .18rem;
        margin-top: .02rem;
        border-color: #484848;
      }
      .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: #484848;
      }
      .el-checkbox__inner::after {
        top: 0.015rem;
        left: .05rem;
      }
    }

    .btn {
      width: 2.28rem;
      height: .57rem;
      background-color: #FF3030;
      font-size: .18rem;
      color: #fff;
      border-radius: .28rem;
      margin-top: .17rem;
    }

  }

  .out {
    position: absolute;
    bottom: .35rem;
    right: .33rem;
    height: .25rem;
    font-size: .18rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #8F8F8F;
    line-height: .25rem;
    cursor: pointer;
  }
    .prefixSearch {
    top: 0;
    padding: .1rem .05rem;
  }
}
</style>
