<template>
  <div class="m-case m-navigation-bar">
    <div class="m-case-tabs">
      <van-tabs v-model="active">
        <van-tab title="DAF x Hunter">
          <div class="m-case-tabs-content">
            <div class="m-case-tabs-content-title">
              Digital Art Fair 2022 艺术展
            </div>
            <img
              class="img1"
              src="@/assets/mobile/9881667196346_.pic@2x.png"
              alt=""
            />
            <div class="m-case-tabs-content-text">
              数码艺术展推出香港数码艺术展 Xperience 2022。该展览将把占地 20,000
              平方英尺的中环场地改造成引人入胜的互动高科技 Web 3.0 艺术体验。
              数以千计的数码艺术作品将在 2022 年香港数码艺术展 Xperience
              期间通过实体、数码展示和虚拟现实在虚拟世界中展示。
            </div>
            <img class="img2" src="@/assets/mobile/Group 4@2x.png" alt="" />
          </div>
        </van-tab>
        <van-tab title="天趣艺术画廊">
          <div class="m-case-tabs-content">
            <div class="m-case-tabs-content-title">
              天趣艺术画廊
            </div>
            <img class="img1" src="@/assets/mobile/Bitmap@261x.png" alt="" />
            <div class="m-case-tabs-content-text">
              天趣国际艺术携手印尼驻香港总领事馆，及香港印尼研究学社协同合作，为庆祝印尼蜡染日共同呈现“向世界遗产致敬：印尼艺术展”。<br>
              蜡染被称为印尼织物，是印尼的国宝和世界非物质文化遗产。对于每个印尼人来说，在蜡染节这一天穿上最好的蜡染服装，是对包括蜡染在内的印尼传统艺术的最高敬意。展览即展出世界非遗蜡染服饰及充满印尼生活风情的艺术作品，展览时间为9月30日至11月11日，期间将举行具印尼特色的蜡染及印尼咖啡工作坊。<br>
              印尼现今人口达2.8亿，近年来经济发展势头强劲，发展成果显著，国际地位日益重要。作为2022年G20轮值主席国和2023年东盟轮值主席国，印尼在世界政治和经济舞台上的表现将更加耀眼夺目。本次展览在这历史大背景下举办更显举足轻重、意义非凡！展览将带领大家感受印尼悠久的世界非遗文化、原汁原味的民族风情和独具特色的匠心精神。本次展览谨在通过向观众展示印尼丰富多彩的历史及艺术文化来推动印尼与香港文化艺术的深入交流，打造一个融合世界非物质文化遗产与当代艺术的国际性文化交流平台，培育浓厚且多元的文化艺术氛围。<br>
              本次大会，Hunter作为合作方，将具有印尼独特民族风情的艺术作品生成数字藏品，并在ATM
              上架发售，为用户带来不一样的购买体验。
            </div>
            <img
              class="img2"
              src="@/assets/mobile/Bitmap@231x (1).png"
              alt=""
            />
          </div>
        </van-tab>
        <van-tab title="香港一铭体育">
          <div class="m-case-tabs-content">
            <div class="m-case-tabs-content-title">
              香港一铭体育
            </div>
            <img class="img-logo" src="@/assets/mobile/Bitmap@2x123.png" alt="">
            <div class="m-case-tabs-content-text">
              一铭体育过去五年秉承专业、负责、认真的教学品质，凭着超过三十多年职业球员及教练的深厚经验，积极推动本港乒乓球专项运动，提升本地球员及青少年表现及水平。而是次送出的数字藏品贵宾卡正正代表一铭体育以及专注乒乓球运动的理念，这款数字藏品与Hunter共同发行的运动类型纪念数字藏品，数字藏品上有一铭的标志以及代表本会的乒乓球用品，而且会有会员的资料作为贵实认证。持有数字藏品的贵宾亲身报名指定课程及场地租赁更可获各项优惠。
            </div>
            <img class="img1" src="@/assets/mobile/Bitmap@2x 32(1).png" alt="" />
            <img
              class="img2"
              src="@/assets/mobile/Group 4@2x14.png"
              alt=""
            />
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
    }
  },
}
</script>

<style lang="less" scoped>
.m-case {
  /deep/ .m-case-tabs {
    // height: .54rem;
    // padding: 0 .15rem;
    background-color: #fff;
    border-top: 0.02rem solid #f1f2f5;
    margin-bottom: 0.12rem;
    .van-tabs__nav {
      align-items: center;
    }
    .van-tabs__wrap {
      height: 0.54rem;
      border-bottom: 0.02rem solid #f1f2f5;
      padding: 0 0.15rem;
    }
    .van-tab {
      width: 0.77rem;
      z-index: 2;
      font-size: 0.13rem;
      color: #000;
    }
    .van-tabs {
      height: 100%;
    }
    .van-tab--active {
      color: #fff;
      font-weight: 400;
      font-size: 0.13rem;
    }
    .van-tabs__line {
      background: linear-gradient(180deg, #ff8787 0%, #ff3030 100%);
      border-radius: 0.13rem;
      width: 1.13rem;
      height: 0.25rem;
      bottom: 0.29rem;
    }
    .m-case-tabs-content {
      box-sizing: border-box;
      padding: 0.15rem 0.27rem 0.44rem 0.28rem;
      width: 100%;
      background-color: #fff;
      .img-logo {
        width: 2rem;
        height: 1.47rem;
        margin-bottom: .1rem;
        margin-left: 50%;
        transform: translate(-50%, 0);
      }
      .img1 {
        width: 3.2rem;
        margin-bottom: 0.07rem;
      }
      .img2 {
        width: 3.19rem;
      }
      .m-case-tabs-content-text {
        font-size: 0.11rem;
        font-weight: 400;
        color: #000000;
        line-height: 0.15rem;
        text-align: justify;
        text-justify: distribute-all-lines; // 这行必加，兼容ie浏览器
        // text-align-last: justify;
        margin-bottom: 0.23rem;
      }
      .m-case-tabs-content-title {
        font-size: 0.22rem;
        font-weight: 900;
        margin-bottom: 0.06rem;
      }
    }
  }
}
</style>
