<template>
  <div class="pay pc-navigation-bar-height min-width">
    <div class="bx">
      <div class="details-head-box">
        <div class="details-head-box-top">
          <img class="top-img" :src="List.nftPath" alt="" />
          <div class="right">
            <div class="title">{{ List.code }}</div>
            <div class="logo">
              <img class="logo-img" src="@/assets/pc/SOLANA@2x.png" alt="" />{{
                List.chain
              }}
            </div>
            <div class="introduce">
            <div class="introduce_top">
              <div
                v-for="item in attr"
                :key="item.index"
                class="introduce_text"
              >
                <p :title="item.head">{{ item.head }}</p>
                <p style="color: #909090;" :title="item.body">{{ item.body }}</p>
              </div>
            </div>
          </div>
          </div>
        </div>
        <div class="buy">
          <div class="price">{{ List.price ? priceDispose(List.price) + '&nbsp;' + List.currency : '' }}</div>
            <div class="right">
              <img class="pay-type" @click="payType = !payType" src="@/assets/pc/pay7@2x.png" alt="">
              <img class="pay-ok" v-show="payType" src="@/assets/pc/Group2Copy4@2x.png" alt="">
              <img class="pay" @click="payBtn" src="@/assets/pc/buy.png" alt="">
            </div>
          </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="payShow"
      width="0rem"
      :show-close="false"
    >
      <pay-dialog :id="id" @payOutBtn="payOutBtn"></pay-dialog>
    </el-dialog>
  </div>
</template>

<script>
import { marketDetails, getNftCommodityByOrderNo, getCategoryData, } from '@/common/api'
import { newAttr, priceDispose } from '@/common/common'
import PayDialog from './pay-dialog/pay-dialog.vue'
export default {
  components: {
    PayDialog,
  },
  data() {
    return {
      payType: false,
      priceDispose,
      introduce: [],
      payShow: false,
      id: {
        nftId: this.$route.query.nftId,
        nftCommodityId: this.$route.query.nftCommodityId,
        orderNo: this.$route.query.orderNo
      },
      orderNo: this.$route.query.orderNo,
      List: {},
      attr: [],
      optionList: [
        // {
        //   id: 1,
        //   img: '/img/option@2x.png',
        // },
        // {
        //   id: 2,
        //   img: '/img/pay9@2x.png',
        // },
        {
          id: 3,
          img: '/img/pay7@2x.png',
        },
      ],
      optionId: 0, // 确定支付方式
    }
  },
  created() {
    if(this.id.nftCommodityId) {
      this.marketDetails()
    }
    if(this.orderNo) {
      this.getNftCommodityByOrderNo()
    }
  },
  methods: {
    async getCategoryData() {
      const res = await getCategoryData(this.List.category)
      this.introduce = res.data[0]
    },
    async getNftCommodityByOrderNo() {
      const res = await getNftCommodityByOrderNo(this.orderNo)
      this.id.nftId = res.data.nftId
      this.id.nftCommodityId = res.data.nftCommodityId
      this.List = res.data
      this.getCategoryData()
    },
    payOutBtn() {
      this.payShow = false
    },
    async marketDetails() {
      const res = await marketDetails(this.id.nftCommodityId)
      this.List = res.data
      this.attr = newAttr(this.List.attr)
      this.getCategoryData()
    },
    payBtn() {
      if(!this.payType) return this.$message.warning('请选择支付方式')
        this.payShow = true
    },
  },
}
</script>

<style lang="less" scoped>
  .pay {
    .bx {
      width: 12.41rem;
      box-sizing: border-box;
      padding-top: 1.01rem;
      margin: 0 auto;
      .details-head-box {
      background-color: #fff;
      width: 100%;
      height: 8.88rem;
      box-sizing: border-box;
      margin-bottom: 0.45rem;
      padding: 1rem 1.55rem .87rem 1.41rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .details-head-box-top {
        display: flex;
        padding-bottom: .63rem;
        border-bottom: .02rem solid #CACACA;
        .right {
          .title {
            font-size: 0.75rem;
            color: #888888;
            height: 1.04rem;
          }
          .logo {
            width: 1.13rem;
            height: 0.4rem;
            background: rgba(0, 0, 0, 0.34);
            border-radius: 0.19rem;
            display: flex;
            box-sizing: border-box;
            justify-content: space-between;
            padding-left: 0.08rem;
            padding-right: 0.11rem;
            align-items: center;
            font-size: 0.17rem;
            color: #ffffff;
            margin-bottom: .41rem;
            .logo-img {
              width: 0.31rem;
              height: 0.31rem;
            }
          }
          .introduce {
            height: 1.21rem;
        margin-top: .1rem;
        margin-bottom: .74rem;
        font-size: .19rem;
        color: #676767;
            .introduce_top {
              display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
              .introduce_text {
            box-sizing: border-box;
            margin-top: .13rem;
            width: 50%;
            p {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .introduce_text:nth-child(2n) {
            padding-left: .4rem;
          }
            }
          }
        }
        .top-img {
          width: 5.01rem;
          height: 5.01rem;
          margin-right: 0.35rem;
        }
      }
      .buy {
            font-size: .28rem;
            color: #646464;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .right {
              position: relative;
              .pay-type {
              cursor: pointer;
              width: 1.53rem;
              height: .49rem;
              margin-right: .2rem;
            }
            .pay-ok {
              position: absolute;
              right: 3.588rem;
              top: .23rem;
              width: .36rem;
              height: .36rem;
              cursor: pointer;
            }
            .pay {
              width: 2.28rem;
              height: .84rem;
              cursor: pointer;
            }
            }
          }
    }
    }
  }
</style>