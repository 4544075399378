<template>
  <div>
    <pc-navigation-bar v-if="!$isMobile"></pc-navigation-bar>
    <m-navigation-bar v-else></m-navigation-bar>
      <p-home-daf v-if="!$isMobile"></p-home-daf>
      <m-home-daf v-else></m-home-daf>
      <pc-footer v-if="!$isMobile"></pc-footer>
      <m-footer v-else></m-footer>
  </div>
</template>

<script>
import PHomeDaf from '@/components/pc/home-daf.vue'
import MHomeDaf from '@/components/mobile/home-daf.vue'
export default {
  components: {
    PHomeDaf,
    MHomeDaf
  }
}
</script>

<style>

</style>