<template>
  <div class="pc-market pc-navigation-bar-height min-width">
    <div class="pc-market-bx">
      <div class="pc-market-bx-titleImg">
        <img src="@/assets/pc/Group 77@2x.png" alt="" />
        <div class="text">数字藏品市场</div>
        <div class="login" @click="userInfo.token ? $router.push('/order') : setLoginShow(true)">
          {{ userInfo.token ? verifyPhone(userInfo.mobile) : '登录/注册' }}
        </div>
      </div>
      <div class="pc-market-bx-content">
        <div class="pc-market-bx-left">
          <div
            class="content"
            :style="optionList.length === 0 ? 'background-color: #f1f1f1;' : ''"
          >
            <div
              class="list"
              :class="item.isSeries ? 'pc-option active-li' : ''"
              v-for="item in optionList"
              :key="item.id"
              @click="activeBtn(item)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="pc-market-bx-right">
          <div
            class="noData"
            :style="optionList.length === 0 ? 'margin-left: 2.2rem;' : 'margin-left: 3rem;'"
            v-if="
              (list.length === 0 && optionList.length === 0) ||
              list.length === 0
            "
          >
            <img src="@/assets/pc/Group 4 Copy.png" alt="" />
            <div class="text">暂无上架的数字藏品</div>
          </div>
          <div class="pc-home-bx-hot-sale" style="margin-bottom: 0.6rem;">
            <div class="pc-home-bx-hot-sale-box">
              <div
                class="pc-home-bx-hot-sale-box-list"
                v-for="(item, index) in list"
                :key="index"
                @click="listBtn(item)"
              >
                <img
                  class="pc-home-bx-hot-sale-box-list-nft"
                  :src="item.nftPath"
                  alt=""
                />
                <img
                  class="pc-home-bx-sale-box-list-searchIcon"
                  @click.stop="searchImgBtn(item.nftPath)"
                  src="@/assets/mobile/ZOOM@2x.png"
                  alt=""
                />
                <div class="pc-home-bx-hot-sale-box-list-introduce">
                  <span>系列</span>
                  <span
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    "
                  >
                    {{ item.category }}
                  </span>
                </div>
                <div class="pc-home-bx-hot-sale-box-list-type">
                  <span>链</span>
                  <div class="pc-home-bx-hot-sale-box-list-type-right">
                    <img src="@/assets/pc/SOLANA@2x.png" alt="" />
                    <span>{{ item.chain }}</span>
                  </div>
                </div>
              </div>
            </div>
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page.sync="page.page"
              :page-size="page.pageSize"
              layout="total, prev, pager, next"
              :hide-on-single-page="page.total <= 9"
              :total="page.total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible" width="7.4rem">
      <div class="search-img">
        <img :src="searchImg" alt="" />
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="loginShow"
      width="0rem"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <login></login>
    </el-dialog>
  </div>
</template>

<script>
import { verifyPhone } from '@/common/common.js'
import Login from '@/components/pc/login'
import { marketList, getCategoryList } from '@/common/api'
import { mapState, mapMutations } from 'vuex'
import Utils from '@/common/utils.js'
export default {
  components: {
    Login,
  },
  data() {
    return {
      dialogVisible: false,
      optionList: [],
      list: [],
      verifyPhone: verifyPhone,
      page: {
        page: 1,
        pageSize: 9,
        total: 0,
      },
      searchImg: '',
    }
  },
  created() {
    if (this.$route.query.type) {
      this.getCategoryList()
    } else {
      this.marketList()
      this.getCategoryList()
    }
  },
  computed: {
    ...mapState(['userInfo', 'loginShow']),
    category() {
      return this.newFormData(this.optionList)
    },
  },
  mounted(){
    var that = this;
    Utils.$on('Painting', function () {
        that.getCategoryList();
    })
},
  methods: {
    ...mapMutations(['setLoginShow']),
    newFormData(List) {
      let newArr = []
      List.forEach((item) => {
        if (item.isSeries) {
          newArr.push(item.name)
        }
      })
      return newArr
    },
    activeBtn(item) {
      this.page.page = 1
      item.isSeries = !item.isSeries
      this.marketList()
    },
    async marketList() {
      const res = await marketList({
        page: this.page.page,
        pageSize: this.page.pageSize,
        category: this.category,
      })
      this.list = res.data.result
      this.page.total = res.data.total
    },
    searchImgBtn(url) {
      this.dialogVisible = true
      this.searchImg = url
    },
    handleCurrentChange(val) {
      this.page.page = val
      this.marketList()
    },
    async getCategoryList() {
      const res = await getCategoryList()
      res.data.forEach((item) => {
        item.isSeries = false
      })
      this.optionList = res.data
      this.optionList.forEach((item) => {
        if (item.name == this.$route.query.type) {
          item.isSeries = true
          this.marketList()
        }
      })
    },
    listBtn(item) {
      this.$router.push(
        `/details?nftCommodityId=${item.nftCommodityId}`
      )
      window.scrollTo(0,0);
    },
  },
}
</script>

<style lang="less" scoped>
.pc-market {
  .pc-market-bx {
    width: 12.4rem;
    margin: 0 auto;
    box-sizing: border-box;
    .pc-market-bx-titleImg {
      position: relative;
      width: 100%;
      height: 3.07rem;
      margin-bottom: 0.55rem;
      img {
        width: 100%;
        height: 100%;
      }
      .text {
        position: absolute;
        top: 1.24rem;
        left: .93rem;
        font-size: 0.36rem;
        font-weight: 900;
      }
      .login {
        cursor: pointer;
        position: absolute;
        top: 0.19rem;
        right: 0.24rem;
        width: 2.11rem;
        height: 0.53rem;
        background: url('../../assets/pc/Group 10.png') no-repeat;
        background-size: 100% 100%;
        font-size: 0.17rem;
        color: #2d2d2d;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .pc-market-bx-content {
      display: flex;
      position: relative;
      .pc-market-bx-left {
        // position: absolute;
        // top: 0;
        width: 2.43rem;
        box-sizing: border-box;
        margin-right: 0.04rem;
        .content {
          background-color: #fff;
          padding-top: 0.59rem;
          padding-left: 0.11rem;
          padding-bottom: 0.1rem;
          .list {
            cursor: pointer;
            box-sizing: border-box;
            margin-bottom: 0.27rem;
            padding-left: 0.16rem;
            border-radius: 0.19rem;
            border: 0.01rem solid #e2dede;
            width: 1.93rem;
            height: 0.46rem;
            padding-right: 0.1rem;
            // display: flex;
            // align-items: center;
            line-height: 0.46rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .active-li {
          text-align-last: left;
          margin-bottom: 0.21rem;
        }
      }
      .pc-market-bx-right {
        flex: 1;
        .noData {
          position: relative;
          width: 3.93rem;
          height: 5.07rem;
          img {
            width: 100%;
            height: 100%;
          }
          .text {
            position: absolute;
            bottom: 0.57rem;
            left: 50%;
            transform: translate(-50%, 0);
            color: #434343;
            font-size: 0.16rem;
          }
        }
        .pc-home-bx-hot-sale {
          margin-bottom: .4rem !important;
        }
        .pc-home-bx-hot-sale-box {
          // justify-content: flex-end;
          // align-content: flex-start;
          margin-bottom: 0;
          .pc-home-bx-hot-sale-box-list {
            margin-left: 0.1rem;
            width: 3.2rem;
            height: 4.09rem;
            margin-bottom: 0.43rem;
            .pc-home-bx-hot-sale-box-list-nft {
              width: 100%;
              height: 2.88rem;
            }
            .pc-home-bx-sale-box-list-searchIcon {
              bottom: 1rem;
              right: 0.28rem;
              width: 0.25rem;
              height: 0.25rem;
            }
            .pc-home-bx-hot-sale-box-list-introduce {
              font-size: 0.15rem;
            }
            .pc-home-bx-hot-sale-box-list-type {
              font-size: .15rem;
              .pc-home-bx-hot-sale-box-list-type-right {
                font-size: .12rem;
                img {
                  width: .27rem;
                  height: .27rem;
                }
              }
            }
          }
        }
      }
    }
  }
  .search-img {
    width: 100%;
    height: 6.5rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
  /deep/ .el-dialog__header {
    padding: 0;
  }
  /deep/ .el-dialog__body {
    padding: 0;
  }
}
</style>
