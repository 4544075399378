<template>
  <div class="pc-home-daf pc-navigation-bar-height min-width">
      <div class="bx">
        <div class="DAF-title">
      <div class="name">Digital Art Fair Xperience
香港展会圆满结束</div>
      <div class="time">2022-11-8 18:30</div>
    </div>
    <img class="daf-img1" src="@/assets/pc/Group 4@2x3.png" alt="">
    <div class="daf-text1">“Digital Art Fair Xperience 香港展会”已经圆满结束，会场展出70多位国际知名的数码艺术家，超过400件价值逾3千万港元数码艺术品，让观众全程投入数字藏品艺术世界。<br><br>

本次参与展览的艺术家阵容强大，包括：香港知名视觉设计师及艺术家又⼀⼭⼈、著名摄影师兼导演夏永康、新媒体艺术家朱⼒⾏；⻄⽅艺术界明星 Beeple、Refik Anadol 及 Pak；中国当代普普艺术家 Jacky Tsai（蔡赟骅）及获奖无数的创意先驱 Victor Wong（⿈宏达）。



</div>
    <img class="daf-img2" src="@/assets/pc/Group 3@2x1.png" alt="">
    <div class="daf-text1">其中，中国当代普普艺术家 Jacky Tsai（蔡赟骅）成为香港展会本年的"年度数码艺术家"。Jacky Tsai擅于揉合中⻄⽂化。他为展览带来⾸个沉浸式艺术空间《⿈⾦⼗年》，以崭新的⼿法回顾其过往⼗年的艺术⽣涯。 《⿈⾦⼗年》由香港⾦像奖最佳视觉效果奖得主⿈宏达联合监制，并获得华语歌王周杰伦与⽇本殿堂级音乐家坂本⿓⼀配乐。Jacky Tsai 透过⽊雕、刺绣、全息投影的数码及实体作品，巧妙结合东⽅传统技艺与西⽅普普艺术。<br><br>

另外，Hunter 结合展会 “Digital Art Fair Xperience” 主题，为游客提供定制数字藏品服务。游客可以选择心仪的数字藏品属性，生成自己专属的数字藏品头像作为纪念。另外，Hunter 投放了数字藏品自动售卖设备，和Decentral Art共同推出环保主题的数字藏品供游客购买。</div>
<img class="daf-img3" src="@/assets/pc/9891667196348_.pic3@2x.png" alt="">
      </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
  .pc-home-daf {
    .bx {
      display: flex;
    flex-direction: column;
    width: 12.4rem;
    margin: 0 auto;
    background-color: #fff;
    box-sizing: border-box;
    padding: .91rem 2.47rem .55rem 2.47rem;
    .DAF-title {
      font-size: .45rem;
      font-weight: 900;
      font-family: STSongti-SC-Black, STSongti-SC;
      margin-bottom: .2rem;
      line-height: .64rem;
      .name {
        width: 5.3rem;
      }
      .time {
        font-size: .19rem;
        font-weight: 400;
        color: #7E7E7E;
      }
    }
    .daf-img1 {
      width: 7.47rem;
      height: 4.92rem;
      margin-bottom: .17rem;
    }
    .daf-text1 {
      font-size: .189rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #000000;
line-height: .27rem;
margin-bottom: .36rem;
word-break: break-all;
    }
    .daf-img2 {
      width: 7.47rem;
      height: 4.92rem;
      margin-bottom: .17rem;
    }
    .daf-img3 {
      width: 7.48rem;
      height: 9.96rem;
    }
    }
  }
</style>