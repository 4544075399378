<template>
  <div class="pc-industry pc-navigation-bar-height min-width">
    <div class="pc-industry-bx">
      <div class="pc-industry-bx-img">
        <img v-show="activeId == 3" src="@/assets/pc/Bitmap@22x.png" alt="">
        <img v-show="activeId == 2" src="@/assets/pc/Bitmap@2x2 (1).png" alt="">
        <img v-show="activeId == 1" src="@/assets/pc/Bitmap@2x (2).png" alt="">
        <img v-show="activeId == 0" src="@/assets/pc/Bitmap@2x (3).png" alt="">
      </div>
      <div class="pc-industry-bx-option">
        <div :class="['pc-industry-bx-option-list', activeId == item.id ? 'pc-option' : '']" v-for="item in optionList" :key="item.id" @click="optionListBtn(item)">{{ item.name }}</div>
      </div>
      <div v-show="activeId == 3" class="pc-industry-bx-text">
        几十年来，专属会员机制一直是品牌与最活跃的客户之间建立深度关系的方式。许多企业对自己的会员机制策略都至少会进行部分定制；然而，大多数机制则遵循以下两种基本模式中的一种：<br><br>

付费会员：用户通常每月或每年支付固定数额的美元。相应地，他们会获得预设好的会员专属权益包。这些权益包括活动门票、访问优质内容的许可、产品折扣等等。这是时下最流行的会员模式，像 SoHo House 这样的俱乐部、Costco 之类的零售商、《纽约时报》等出版媒体都采用该模式。<br><br>

免费会员：用户不需要为会员身份支付任何费用。相反，注册之后，基于个人购买活动或相关标准，用户获得不同层级的权益。这种会员模式通常不是被用于为社区设立门槛，而是用来养成客户忠诚度，如 Walgreens 的 myWalgreens。<br><br>

这些传统模式本身没有什么问题，随着非同质化代币（NFT）和相关 Web3 技术 的引入，会员机制也正在获得它所亟需的升级。而这些变化正同时为企业和 NFT 持有者释放新的增长机会和收入激励。<br><br>

在典型的会员机制中，会员证的经济价值不会随着时间的推移而增加。但对 NFT 来说，情况则完全不同，这就创造了一个全新的态势。有史以来第一次，会员机制的价值 —— 以及个人所持有的会员证的价值 —— 不再完全由品牌方来决定。反而，它成了双向的。<br><br>

对于传统的会员机制，企业可能会变得自满，并逐渐削减会员权益。同样，用户可能会流向提供类似权益的其它品牌。然而，有了 NFT 会员机制，双方都有动力去积极寻找提升品牌品质的新途径，进而也提升了代表会员身份的 NFT 的价值。<br><br>

由于会员身份 NFT 本身就具有货币价值，所以随着时间推移去提高会员身份的价格，成了持有者和品牌共同的动机
      </div>
      <div v-show="activeId == 2" class="pc-industry-bx-text">
        近年来，数字藏品因其技术特点和先锋性迅速渗透文化圈层甚至公共意识，各种盛大的体育赛事和取得重大突破的中国航天事业，为纪念这历史性的时刻，在发行实物纪念品的同时，也会发行数字藏品纪念品。<br><br>

北京冬奥会开幕以来，吉祥物冰墩墩迅速出圈，成为顶流IP，线下门店一墩难求，这种现象级的火爆甚至已经延续到数字线上领域。2月12日，由官方授权发行的冰墩墩NFT盲盒在平台发售，该盲盒一经推出不仅销售秒空，还引发了抢购热潮。<br><br>

神舟十四飞船发射之际，中国探月航天同样践行先锋和探索的航天理念，以超现实的方式，推出首个数字飞船藏品，尝试通过新技术，让更多普通人体验航天热情和科技自豪，以科技实现未来幻想。

      </div>
      <div v-show="activeId == 1" class="pc-industry-bx-text">
        NFT门票的实用性：<br><br>

从用户的角度来看，NFT门票的功能实际上与标准的数字门票相同。<br><br>

要参加活动时，持票者只需在手机上扫描门票，然后进入相关活动。对于用户来说，NFT门票的不同之处在于事件结束后所发生的情况。在门票被扫描后，它就会成为持有者基于以太坊加密钱包中的NFT，然后可以作为数字纪念品保存、交易或出售给其他用户。<br><br>

NFT门票的目的不仅仅是对消费者的效用，它们还为消费者和活动组织者提供了超出标准门票程序范围的安全保障。区块链技术提供的固有安全功能将增加门票的安全性。<br><br>

同时，区块链技术通过对每笔交易都保留着可验证的记录，防止这些黄牛大规模购买门票。如果某些买家有恶意行为，活动组织者都会知道。<br><br>

对于区块链和门票行业来说，NFT门票是非常好的技术应用。通过使用区块链和NFT的技术优势来解决门票代理的需求并提供更好的购票体验。<br><br>

      </div>
      <div v-show="activeId == 0" class="pc-industry-bx-text">
        艺术可以说是NFT赋能过程中的第一批受益者，传统艺术品的可复制性是艺术领域的痛<br>点，侵权盗版的恶劣现象造成艺术品的稀缺性被大大稀释，在市面大量赝品的冲击下是艺术价值的此长彼消。这一现象一直持续到2021年，这一年，beeple一幅NFT艺术品<br>《第一天：最初的5000天》被拍出了近7000万美元的天价，NFT的概念快速出圈，人们开始兴奋的讨论NFT和艺术之间的无限可能！<br><br>

一石激起千层浪，NFT拥有艺术品缺乏的稀缺性，艺术品具备NFT所需的价值承载能力，“NFT+艺术”完美契合，再加上夹杂的金融价值属性，在艺术领域的链式爆发也就呈雷霆之势迅速蔓延。齐白石的《贝叶工虫图》、傅抱石的《待细把江山图画》，许多传统艺术珍品开始借NFT与数字时代接轨，让世人一睹奇彩；NIKE、可口可乐、香奈儿等众多品牌也纷纷发行限量数字藏品推波助澜的提升NFT和艺术的融汇厚发。<br><br>

“NFT+艺术”不仅解决了艺术品空发、滥发、赝发等问题，保证了艺术品的稀缺性，也使得进入艺术收藏界的门槛被大大降低，大众和艺术的固有壁垒被逐渐模糊，NFT让艺术重新回归生活之中，让更多普通人有机会加入这场艺术的饕餮盛宴。
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      optionList: [
        {
          id: 0,
          name: '艺术品',
        },
        {
          id: 1,
          name: '门票',
        },
        {
          id: 2,
          name: '纪念品',
        },
        {
          id: 3,
          name: 'VIP会员',
        },
      ],
      activeId: 0,
    }
  },
  methods: {
    optionListBtn(item) {
      this.activeId = item.id
    },
  }
}
</script>

<style lang="less" scoped>
  .pc-industry {
    .pc-industry-bx {
      width: 12.4rem;
      margin: 0 auto;
      background-color: #fff;
      padding-top: .75rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      .pc-industry-bx-img {
        width: 8.21rem;
        height: 3.67rem;
        margin-bottom: .44rem;
          img {
            width: 100%;
            height: 100%;
          }
      }
      .pc-industry-bx-option {
        width: 100%;
        display: flex;
        padding-left: 2.87rem;
        padding-right: 2.61rem;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        margin-bottom: .44rem;
        .pc-industry-bx-option-list {
          font-size: .19rem;
          font-weight: 400;
          cursor: pointer;
        }
      }
      .pc-industry-bx-text {
        width: 7.23rem;
        text-align:justify;
        text-justify:auto;
        line-height: .27rem;
        margin-bottom: 1.76rem;
      }
    }
  }
</style>