<template>
  <div>
    <pc-navigation-bar v-if="!$isMobile"></pc-navigation-bar>
      <p-order-list v-if="!$isMobile"></p-order-list>
      <pc-footer v-if="!$isMobile"></pc-footer>
  </div>
</template>

<script>
import POrderList from '@/components/pc/order-list.vue'
export default {
  components: {
    POrderList,
  }
}
</script>

<style>

</style>