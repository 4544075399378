<template>
  <div class="m-course m-navigation-bar">
    <div class="m-course-title1">
      <p class="title">如何在线下智能终端购买数字藏品？</p>
      <p>简单三步即可完成交易</p>
    </div>
    <div class="m-course-option">
      <div class="item">
        <img src="@/assets/mobile/Rectangle 2 Copy 2@2x.png" alt="" />
        <p class="text1">第一步</p>
        <p class="text2">选中您心仪的数字藏品</p>
        <p class="text3">每次下单只能购买一个数字藏品</p>
      </div>
      <div class="item">
        <img src="@/assets/mobile/Rectangle 2 Copy 3@2x.png" alt="" />
        <p class="text1">第二步</p>
        <p class="text2">输入手机号码并验证</p>
        <p class="text3" style="width: 1.72rem; line-height: 0.15rem;">
          手机号码是购买数字藏品的凭证，购买成功后会收到短信
        </p>
      </div>
      <div class="item" style="margin-bottom: 0;">
        <img src="@/assets/mobile/Group 7 Copy@2x.png" alt="" />
        <p class="text1">第三步</p>
        <p class="text2">扫码支付，数字藏品购买成功</p>
        <p class="text3">支持微信/支付宝/Visa/Master等支付方式</p>
      </div>
    </div>
    <div class="m-course-title2">
      如何查看我购买的数字藏品？
    </div>
    <div class="m-course-market">
      <p class="title">在商城登入个人中心进行查看</p>
      <p class="text">已经购买数字藏品的用户，可以在商城查看个人的数字藏品</p>
      <div class="btn" @click="$router.push('/market')">去商场</div>
    </div>
    <div class="m-course-title3">
      如何提取我的数字藏品？
    </div>
    <div class="m-course-option2">
      <div class="item">
        <img src="@/assets/mobile/Rectangle Copy 13@322x.png" alt="" />
        <p class="text1">第一步</p>
        <p class="text2">填入跟数字藏品相同网络的地址</p>
        <p class="text3">
          相同网络的提取地址非常重要，如果填错，将会对数字藏品资产造成不可挽回的损失，在提取的时候，请用户务必仔细核对网络和地址的正确性
        </p>
      </div>
      <div class="item" style="margin-bottom: 1rem;">
        <img src="@/assets/mobile/Rectangle Copy 14@1232x.png" alt="" />
        <p class="text1">第二步</p>
        <p class="text2">提交申请，进行短信验证</p>
        <p class="text3">
          为了保证资产安全，数字藏品的每次提取，我们都需要对用户手机号码进行短信验证，请保证手机能正常通信
        </p>
      </div>
      <div class="item">
        <img src="@/assets/mobile/Group 8 Copy@412x.png" alt="" />
        <p class="text1">第三步</p>
        <p class="text2">平台审核，提取成功</p>
        <p class="text3" style="width: 2.55rem;">
          平台会对用户的提取请求进行审核，<br>
通过后，会把数字藏品转到用户的提取地址
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
}
</script>

<style lang="less" scoped>
.m-course {
  .m-course-title1 {
    font-size: 0.13rem;
    padding: 0.72rem 0 0.34rem 0;
    text-align: center;
    .title {
      font-size: 0.19rem;
      font-weight: 900;
      margin-bottom: 0.07rem;
    }
  }
  .m-course-option {
    background-color: #fff;
    padding: 0.45rem 0 0.86rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .item {
      text-align: center;
      margin-bottom: 0.79rem;
      img {
        width: 1.36rem;
        height: 1.36rem;
        margin-bottom: 0.14rem;
      }
      .text1 {
        color: #3f3f3f;
        font-size: 0.13rem;
        margin-bottom: 0.04rem;
      }
      .text2 {
        color: #3f3f3f;
        font-size: 0.16rem;
        font-weight: 600;
        margin-bottom: 0.05rem;
      }
      .text3 {
        color: #7f7f7f;
        font-size: 0.11rem;
        margin-bottom: 0.05rem;
      }
    }
  }
  .m-course-title2 {
    padding: 0.72rem 0 0.27rem 0;
    text-align: center;
    font-size: 0.19rem;
    font-weight: 900;
  }
  .m-course-market {
    color: #000;
    position: relative;
    width: 100%;
    height: 2.16rem;
    background: url('../../assets/mobile/Group 15@22x.png') no-repeat;
    background-size: 100% 100%;
    .title {
      font-size: 0.18rem;
      position: absolute;
      top: 0.39rem;
      left: 0.23rem;
    }
    .text {
      font-size: 0.13rem;
      position: absolute;
      top: 0.68rem;
      left: 0.23rem;
      color: #3f3f3f;
    }
    .btn {
      background: url('../../assets/mobile/Group 11@2x.png') no-repeat;
      background-size: 100% 100%;
      width: 1.18rem;
      height: 0.43rem;
      color: #fff;
      font-size: 0.14rem;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 1.51rem;
      left: 1.29rem;
    }
  }
  .m-course-title3 {
    padding: 0.72rem 0 0.2rem 0;
    text-align: center;
    font-size: 0.19rem;
    font-weight: 900;
  }
  .m-course-option2 {
    width: 100%;
    height: 10.17rem;
    background: url('../../assets/mobile/Group 10 Copy@221x.png') no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding-top: .35rem;
    padding-bottom: .61rem;
    padding-left: .81rem;
    padding-right: .49rem;
    .item {
      margin-bottom: .55rem;
      img {
        width: 1.36rem;
        height: 1.36rem;
        margin-bottom: 0.09rem;
        margin-left: .42rem;
      }
      .text1 {
        font-size: 0.13rem;
        font-weight: 600;
        color: #3f3f3f;
        margin-bottom: 0.03rem;
      }
      .text2 {
        font-size: 0.16rem;
        font-weight: 600;
        color: #3F3F3F;
        margin-bottom: 0.06rem;
      }
      .text3 {
        width: 2.28rem;
        font-size: 0.13rem;
        font-weight: 600;
        color: #3F3F3F;
        line-height: 0.18rem;
      }
    }
  }
}
</style>
