<template>
  <div class="pc-case pc-navigation-bar-height min-width">
    <div class="pc-case-bx">
      <div class="pc-case-bx-left">
        <ul>
          <li :class="activeId == item.id ? 'pc-option active-li' : ''" v-for="item in list" :key="item.id" @click="activeBtn(item)">{{ item.name }}</li>
        </ul>
      </div>
      <div class="pc-case-bx-right">
        <cas-daf v-show="activeId == 0"></cas-daf>
        <cas-gallery v-show="activeId == 1"></cas-gallery>
        <cas-sports v-show="activeId == 2"></cas-sports>
      </div>
    </div>
  </div>
</template>

<script>
import CasDaf from '@/components/pc/case-components/DAF.vue'
import CasGallery from '@/components/pc/case-components/gallery.vue'
import CasSports from '@/components/pc/case-components/sports.vue'
export default {
  components: {
    CasDaf,
    CasGallery,
    CasSports
  },
  data() {
    return {
      list: [
        {
          id: 0,
          name: 'DAF x Hunter',
        },
        {
          id: 1,
          name: '天趣艺术画廊',
        },
        {
          id: 2,
          name: '香港一铭体育',
        },
      ],
      activeId: 0,
    }
  },
  methods: {
    activeBtn(item) {
      this.activeId = item.id
    },
  }
}
</script>

<style lang="less" scoped>
  .pc-case {
    .pc-case-bx {
      width: 12.4rem;
      background-color: #fff;
      margin: 0 auto;
      display: flex;
      padding-bottom: .84rem;
      .pc-case-bx-left {
        width: 2.43rem;
        padding-left: .11rem;
        box-sizing: border-box;
        padding-top: 1.09rem;
        ul li {
          margin-bottom: .27rem;
          padding-left: .16rem;
        }
        .active-li {
          text-align-last: left;
          margin-bottom: .21rem;
        }
      }
      .pc-case-bx-right {
        flex: 1;
        padding-top: .91rem;
        box-sizing: border-box;
      }
    }
  }
</style>