<template>
  <div  class="DAF">
    <div class="DAF-title">
      <div class="name">Digital Art Fair 2022 艺术展</div>
    </div>
    <img class="daf-img1" src="@/assets/pc/Bitmap.png" alt="">
    <div class="daf-text1">数码艺术展推出香港数码艺术展 Xperience 2022。该展览将把占地 20,000 平方英尺的中环场地改造成引人入胜的互动高科技 Web 3.0 艺术体验。 数以千计的数码艺术作品将在 2022 年香港数码艺术展 Xperience 期间通过实体、数码展示和虚拟现实在虚拟世界中展示。

</div>
    <img class="daf-img2" src="@/assets/pc/9831667196305_.pic.png" alt="">
  </div>
</template>

<script>
export default {
  name: 'DAF',
}
</script>

<style lang="less" scoped>
  .DAF {
    .DAF-title {
      font-size: .45rem;
      font-weight: 900;
      font-family: STSongti-SC-Black, STSongti-SC;
      margin-bottom: .28rem;
    }
    .daf-img1 {
      width: 7.48rem;
      height: 4.36rem;
      margin-bottom: .51rem;
    }
    .daf-text1 {
      line-height: .27rem;
      margin-bottom: .48rem;
      font-size: .19rem;
      width: 7.23rem;
    }
    .daf-img2 {
      width: 7.48rem;
      height: 9.95rem;
    }
  }
</style>