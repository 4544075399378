<template>
  <div class="details pc-navigation-bar-height min-width">
    <div class="bx">
      <div class="details-head-box">
        <div class="details-head-box-top">
          <div class="top-img">
            <img :src="List.nftPath" alt="" />
          </div>
          <div class="right">
            <div class="title" :style="List.code.length >= 11 ? 'font-size: .34rem;' : 'font-size: .75rem;'">{{ List.code }}</div>
            <div class="logo">
              <img class="logo-img" src="@/assets/pc/SOLANA@2x.png" alt="" />{{
                List.chain
              }}
            </div>
            <div class="introduce">
            <div class="introduce_top">
              <div
                v-for="item in attr"
                :key="item.index"
                class="introduce_text"
              >
                <p :title="item.head">{{ item.head }}</p>
                <p style="color: #909090;" :title="item.body">{{ item.body }}</p>
              </div>
            </div>
          </div>
          <div class="buy">
            <img src="@/assets/pc/buy.png" @click="PayListBtn" alt="">{{ List.price ? priceDispose(List.price) + '&nbsp;' + List.currency : '' }}
          </div>
          </div>
        </div>
        <div class="details-head-box-bottom">
          <!-- Sed mollis felis orci, id efficitur elit ornare id. Donec at odio et
          nisi venenatis tristique eu porta libero. Quisque et sagittis sem.
          Aliquam massa eros, tincidunt at blandit non, fermentum eget ex.
          Nullam sollicitudin mauris ac placerat lacinia. Aliquam neque tortor,
          molestie ut malesuada vel, tempus nec sapien. Cras egestas imperdiet
          mi, eget egestas ipsum blandit id. Vestibulum ante ipsum primis in
          faucibus orci luctus et ultrices posuere cubilia Curae; Suspendisse
          non lectus eget purus dapibus accumsan non non turpis. Aliquam quis
          nulla tortor. -->
          {{ introduce.descriptionZhCn }}
        </div>
      </div>
      <div class="series" v-if="swiperList.length !== 0">
        <swiper :options="swiperOption" ref="mySwiper">
          <swiper-slide v-for="(item, index) in swiperList" :key="index">
            <div class="swiperContent">
              <div class="series_img">
                <div class="right_img" v-for="value in item" :key="value.nftId">
                  <div class="top" @click="detailsBtn(value)">
                    <img class="img" :src="value.nftPath" alt="" />
                    <img class="search" @click.stop="searchImgBtn(value.nftPath)" src="@/assets/pc/ZOOM@2x.png" alt="">
                  </div>
                  <div class="series-introduce">
                    <div class="series-introduce-top">
                      <div>系列</div>
                      <div>{{ value.category }}</div>
                    </div>
                    <div class="series-introduce-bottom">
                      <div>链</div>
                      <div style="font-size: .15rem; font-weight: 400;">
                        <img src="@/assets/pc/SOLANA@2x.png" alt="">{{ value.chain }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </swiper-slide>
          <div class="swiper-button-prev" slot="button-prev">
          </div>
          <div class="swiper-button-next" slot="button-next">
          </div>
        </swiper>
      </div>
      <div class="details-bottom">
        一、Hunter艺术版权作品权益须知：著作权人同意，在交易完成后，您将获得该数字艺术品除人身权利外的其他著作权（包括复制权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权等）。 特别提示：如您有意对该数字艺术品进行商用许可、授权等，建议您与著作权人订立书面的“著作权转让协议”并进行著作权转让备案登记。<br>
二、Hunter艺术衍生品作品权益须知：发售方同意并承诺，在交易完成后，您即享有对该数字艺术品进行占有、使用、处分的权利。 特别提示：您对该数字艺术品的占有、使用、处分不得侵犯作者著作权，即您只能以合理的或著作权人可预见的方式展示、展览、汇编该数字资产中载有的作品，亦可向其他人转让该数字艺术品。如您需要对该数字艺术品做出复制、出租、改编、放映、广播或进行网络传播等涉及作品著作权授权的行为，您应获得著作权人的相应授权，并订立相关的协议。<br><br><br><br>
为更好服务Hunter艺术平台的各位用户，Hunter艺术特此公告数字藏品的二次交易细则。鉴于不同属性的数字艺术的二次交易细则各有不同，请各位用户务必审慎阅读并同意相关规则后进行相应操作，以免造成不必要损失。<br>
1、依据我国法律要求，特定条件下的数字藏品的二次交易不支持任何形式的变相炒作，我们坚决反对数字藏品炒作、场外交易、欺诈，或以任何其他非法、侵权方式使用的违法违规行为，请大家注意甄别上述涉嫌网络欺诈的行为及其相关风险。<br>
2、数字藏品为虚拟数字商品，购买前请完成实名认证，未满18周岁人群禁止购买。<br>
3、本商品一经出售不支持七天无理由退换货。
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible" width="7.4rem">
      <div class="search-img">
        <img :src="searchImg" alt="" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
Array.prototype.indexOf = function (val) {
  for (var i = 0; i < this.length; i++) {
    if (this[i] == val) return i
  }
  return -1
}
Array.prototype.remove = function (val) {
  var index = this.indexOf(val)
  if (index > -1) {
    this.splice(index, 1)
  }
}
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import {
  marketDetails,
  marketList,
  createNftCommodityOrder,
  getCategoryData,
} from '@/common/api'
import { mapState } from 'vuex'
import { newAttr, priceDispose } from '@/common/common'
export default {
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      searchImg: '',
      priceDispose,
      dialogVisible: false,
      dialogImageUrl: '',
      List: {
        code: '',
      },
      introduce: [], // 商品描述信息
      id: this.$route.query.nftCommodityId,
      attr: [],
      swiperList: [],
      LikeList: [],
      // [ { head: 'black', body: 'test' },{ head: 'black2', body: 'test2' },{ head: 'black3', body: 'test3' } ],
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 3500,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        speed: 800,
        // 设置点击箭头
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  },
  computed: {
    ...mapState(['userInfo']),
    swiper() {
      return this.$refs.mySwiper.swiper
    },
  },
  beforeRouteLeave(to,from,next){
      to.meta.keepAlive = true
      next(0)
    },
  watch: {
    $route(to, from) {
      if (to.query.nftCommodityId !== from.query.nftCommodityId) {
        this.marketDetails(to.query.nftCommodityId)
      }
    },
  },
  created() {
    if (!this.List.code) {
      this.marketDetails().then(() => {
        this.marketList()
        this.getCategoryData()
      })
    }
  },
  methods: {
    searchImgBtn(url) {
      this.dialogVisible = true
      this.searchImg = url
    },
    async getCategoryData() {
      const res = await getCategoryData(this.List.category)
      this.introduce = res.data[0]
    },
    // 改造轮播图数据
    newSwiperList(List) {
      let result = []
      for (let i = 0; i < List.length; i += 3) {
        result.push(List.slice(i, i + 3))
      }
      return result
    },
    async marketList() {
      const res = await marketList({
        chain: [this.List.chain],
        status: 'enabled',
        page: 1,
        pageSize: 999,
      })
      res.data.result.forEach((item) => {
        if (item.nftId === this.List.nftId) {
          res.data.result.remove(item)
        }
      })
      this.swiperList = this.newSwiperList(res.data.result)
    },
    async marketDetails(data) {
      const marketDetails2 = marketDetails(data ? data : this.id)
      const res = await marketDetails2
      this.List = res.data
      this.attr = newAttr(this.List.attr)
    },
    detailsBtn(obj) {
      this.List.nftId = obj.nftId
      this.id = obj.nftCommodityId
      this.marketList()
      this.$router.push(
        `/details?nftCommodityId=${obj.nftCommodityId}`
      )
      // this.marketDetails()
      window, scrollTo(0, 0)
    },
    async PayListBtn() {
      if (this.List.status !== 'enabled') return this.$message.warning('该订单不是可售状态')
      const res = await createNftCommodityOrder({
        nftId: parseInt(this.List.nftId),
      })
      this.$router.push(
        `/pay?nftId=${this.List.nftId}&nftCommodityId=${this.id}&orderNo=${res.data.orderNo}`
      )
      window.scrollTo(0,0);
    },
  },
}
</script>

<style lang="less" scoped>
.details {
  .bx {
    width: 12.41rem;
    margin: 0 auto;
    box-sizing: border-box;
    padding-top: 1.01rem;
    .details-head-box {
      background-color: #fff;
      width: 100%;
      height: 8.88rem;
      box-sizing: border-box;
      margin-bottom: 0.45rem;
      padding: 1rem 1.55rem 0.55rem 1.41rem;
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      .details-head-box-top {
        display: flex;
        margin-bottom: .64rem;
        .right {
          position: relative;
          .title {
            color: #888888;
            height: 1.04rem;
          }
          .logo {
            width: 1.13rem;
            height: 0.4rem;
            background: rgba(0, 0, 0, 0.34);
            border-radius: 0.19rem;
            display: flex;
            box-sizing: border-box;
            justify-content: space-between;
            padding-left: 0.08rem;
            padding-right: 0.11rem;
            align-items: center;
            font-size: 0.17rem;
            color: #ffffff;
            margin-bottom: .41rem;
            .logo-img {
              width: 0.31rem;
              height: 0.31rem;
            }
          }
          .introduce {
            height: 1.21rem;
        margin-top: .1rem;
        margin-bottom: .74rem;
        font-size: .19rem;
        color: #676767;
            .introduce_top {
              display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
              .introduce_text {
            box-sizing: border-box;
            margin-top: .13rem;
            width: 50%;
            p {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .introduce_text:nth-child(2n) {
            padding-left: .4rem;
          }
            }
          }
          .buy {
            position: absolute;
            left: 0;
            bottom: 0;
            font-size: .28rem;
            color: #646464;
            display: flex;
            align-items: center;
            img {
              width: 2.28rem;
              height: .84rem;
              margin-right: .36rem;
              cursor: pointer;
            }
          }
        }
        .top-img {
          width: 5.01rem;
          height: 5.01rem;
          margin-right: 0.35rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .details-head-box-bottom {
        line-height: 0.28rem;
        font-size: 0.2rem;
        font-weight: 400;
        color: #adadad;
      }
    }
    .details-bottom {
      width: 100%;
      background-color: #fff;
      height: 5.33rem;
      box-sizing: border-box;
      padding: .55rem .59rem .83rem .75rem;
      margin-bottom: .56rem;
      font-size: .16rem;
font-weight: 400;
color: #ADADAD;
line-height: .25rem;
    }
    .series {
    // padding: 0 50px;
    padding-bottom: .56rem;
    height: 5.39rem;

    .swiperContent {
      display: flex;
      justify-content: center;

      .series_img {
        display: flex;
        // width: 834px !important;
        overflow: hidden;
      }

      .right_img {
        cursor: pointer;
        width: 3.94rem;
        height: 5.09rem;
        margin-bottom: 0;
        margin-left: 0;
        margin-right: .3rem;
        background-color: #fff;
        border-radius: .11rem;
        padding: .33rem .2rem .32rem .2rem;
        box-sizing: border-box;
        .top {
          position: relative;
          width: 3.55rem;
          height: 3.56rem;
        img {
          width: 100%;
          height: 100%;
        }
        .search {
          width: .29rem;
          height: .29rem;
          position: absolute;
          top: 3.16rem;
          right: .12rem;
          cursor: pointer;
        }
        }
        .series-introduce {
          color: #000;
          font-weight: 600;
          font-size: .19rem;
          .series-introduce-top {
            padding-top: .12rem;
            padding-bottom: .05rem;
            display: flex;
            justify-content: space-between;
            border-bottom: .02rem solid #ccc
          }
          .series-introduce-bottom {
            padding-top: .12rem;
            display: flex;
            justify-content: space-between;
            img {
              width: .32rem;
              height: .31rem;
              margin-right: .05rem;
            }
          }
        }
      }
    }

    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-container-horizontal>.swiper-pagination-bullets {
      display: none;
    }

    .swiper-button-prev {
      width: .88rem !important;
      height: .88rem !important;
      background: url('../../assets/pc/inquire-back@2x.png') no-repeat !important;
      background-size: 100% 100% !important;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      margin-top: 0 !important;
    }

    .swiper-button-next {
      width: .88rem !important;
      height: .88rem !important;
      background: url('../../assets/pc/inquire-next@2x.png') no-repeat !important;
      background-size: 100% 100% !important;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0, -50%);
      margin-top: 0 !important;
    }
  }
  }
  .search-img {
    width: 100%;
    height: 6.5rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
  /deep/ .el-dialog__header {
    padding: 0;
  }
  /deep/ .el-dialog__body {
    padding: 0;
  }
}
</style>
