<template>
  <div>
    <pc-navigation-bar v-if="!$isMobile"></pc-navigation-bar>
    <m-navigation-bar v-else></m-navigation-bar>
      <p-course v-if="!$isMobile"></p-course>
      <m-course v-else></m-course>
      <pc-footer v-if="!$isMobile"></pc-footer>
      <m-footer v-else></m-footer>
  </div>
</template>

<script>
import PCourse from '@/components/pc/course.vue'
import MCourse from '@/components/mobile/course.vue'
export default {
  components: {
    PCourse,
    MCourse
  }
}
</script>

<style>

</style>