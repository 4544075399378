<template>
  <div class="my">
    <div class="head">
      <div class="title">
        <p>请输入银行卡信息</p>
      </div>
      <div class="form">
        <el-form
          ref="ruleForm"
          :model="formData"
          :rules="rules"
          label-width="80px"
        >
          <!-- <el-dropdown size="small" trigger="click" placement="bottom-start">
            <span class="el-dropdown-link" style="cursor: pointer;">
              {{ value }}
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="value in areaCode" :key="value.value">{{ value.value }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown> -->
          <el-form-item prop="bankNumber">
            <el-input
              class="right_input"
              v-model="formData.bankNumber"
              placeholder="请输入银行卡号"
              ref="cardInput"
              @input="formatCardNumber"
              type="text"
            ></el-input>
          </el-form-item>
          <el-form-item prop="cvc">
            <el-input
              v-model="formData.cvc"
              type="text"
              maxlength="23"
              placeholder="请输入安全码"
            ></el-input>
          </el-form-item>
          <el-form-item prop="time">
            <el-date-picker
              class="picker"
              v-model="formData.time"
              format="MM/yyyy"
              value-format="MM/yyyy"
              clear-icon="''"
              :editable="false"
              type="month"
              placeholder="请选择有效年月"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <div class="login">
        <el-button class="btn" @click="payBtn">支付</el-button>
      </div>
    </div>
    <p class="out" @click="outBtn">取消</p>
  </div>
</template>

<script>
import { validateCreditCard } from '@/common/common.js'
import {
  getStripeTokenId,
  stripePay,
} from '@/common/api.js'
export default {
  props: ['id'],
  data() {
    return {
      formData: {
        bankNumber: '',
        cvc: '',
        time: '',
      },
      rules: {
        bankNumber: [
          { required: true, message: '请输入银行卡号', trigger: 'blur' },
          { validator: validateCreditCard, trigger: 'blur' },
          { validator: validateCreditCard, trigger: 'change' },
        ],
        cvc: [
          { required: true, message: '请输入安全码', trigger: 'blur' },
          { min: 3, max: 4, message: '请输入正确的安全码', trigger: 'blur' },
          { min: 3, max: 4, message: '请输入正确的安全码', trigger: 'change' }
        ],
        time: [{ required: true, message: '请选择有效年月', trigger: 'blur' }],
      },
    }
  },
  watch: {},
  computed: {},
  created() {
  },
  methods: {
    payBtn() {
      this.$refs.ruleForm.validate(async(valid) => {
        if (valid) {
          let time = this.formData.time.split('/')
          let expMonth = time[0]
          let expYear = time[1]
          const res = await getStripeTokenId({
            expMonth,
            expYear,
            bankNumber: this.formData.bankNumber,
            cvc: this.formData.cvc,
          })
            if (res.data.token) {
              const res3 = await stripePay({
                orderNo: this.id.orderNo,
                token: res.data.token,
              })
              if (res3.data.status === 'succeeded') {
                this.formData.bankNumber = ''
                this.formData.cvc = ''
                this.formData.time = ''
                this.$router.push('/order')
                this.$message.success('支付成功')
              } else {
                this.$message.error('支付失败,请稍后重新支付')
              }
            }
        }
      })
    },
    // 为银行卡输入框添加四位数一个空格
    formatCardNumber(cardNum) {
      // 获取input的dom对象，element ui的el-input这样才能拿到
      const input = this.$refs.cardInput.$el.getElementsByTagName('input')[0]
      const cursorIndex = input.selectionStart
      const lineNumOfCursorLeft = (
        cardNum.slice(0, cursorIndex).match(/\s/g) || []
      ).length
      const noLine = cardNum.replace(/\s/g, '')
      const newCardNum = noLine
        .replace(/\D+/g, '')
        .replace(/(\d{4})/g, '$1 ')
        .replace(/\s$/, '')
      const newLineNumOfCursorLeft = (
        newCardNum.slice(0, cursorIndex).match(/\s/g) || []
      ).length
      const newCursorIndex =
        cursorIndex + newLineNumOfCursorLeft - lineNumOfCursorLeft
      this.$nextTick(() => {
        this.formData.bankNumber = newCardNum
        this.$nextTick(() => {
          input.selectionStart = newCursorIndex
          input.selectionEnd = newCursorIndex
        })
      })
    },
    outBtn() {
      this.formData =  {
        bankNumber: '',
        cvc: '',
        time: '',
      },
      this.$emit('payOutBtn')
    },
  },
}
</script>

<style lang="less" scoped>
.my {
  background: url(../../../assets/pc/pay2@2x.png) no-repeat;
  box-sizing: border-box;
  background-size: 100%;
  width: 5.63rem;
  height: 6.12rem;
  padding: .74rem .8rem .35rem .8rem;
  position: relative;
  margin-left: -3rem;
  z-index: 9;
  .title {
    box-sizing: border-box;
      width: 4.68rem;
      height: .66rem;
      font-size: .2rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: .33rem;
  }
  /deep/ .form {
    margin-bottom: .67rem;
    .el-form-item__content {
      margin-left: 0 !important;
    }
    .el-input__inner {
      font-size: .18rem;
      height: .68rem;
      border-radius: .34rem;
      padding: 0 .28rem;
    }
    .el-form-item {
      margin-bottom: .28rem;
    }
    .el-form-item__error {
      padding-left: .28rem;
    }
    .picker .el-input__icon {
      display: none;
    }
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 100%;
    }
  }
  .login {
    text-align: center;
  }
  .login .btn {
    width: 2.28rem;
      height: .57rem;
      background-color: #FF3030;
      font-size: .18rem;
      color: #fff;
      border-radius: .28rem;
      margin-top: .17rem;
  }
  .out {
    position: absolute;
    bottom: .35rem;
    right: .33rem;
    height: .25rem;
    font-size: .18rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #8F8F8F;
    line-height: .25rem;
    cursor: pointer;
  }
}
</style>
