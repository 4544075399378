<template>
  <div>
    <pc-navigation-bar v-if="!$isMobile"></pc-navigation-bar>
      <p-details v-if="!$isMobile"></p-details>
      <pc-footer v-if="!$isMobile"></pc-footer>
  </div>
</template>

<script>
import PDetails from '@/components/pc/details.vue'
export default {
  components: {
    PDetails,
  }
}
</script>

<style>

</style>