import Vue from 'vue'
import axios from 'axios'
import store from '@/store/index'
import router from '@/router/index'
import { Loading, Message } from 'element-ui'
import { Toast } from 'vant';
const isMobile = Vue.prototype.$isMobile
// 定义 loading
let loading
// let url = '/website'
let url = ''
// if(window.location.hostname =="localhost"){
// var url='https://test.api.hk-wallet.com'
// }else{
// var url=''
// }
// loading开始 方法
function startLoading() {
  if(isMobile) {
    Toast.loading({
      message: '加载中',
      forbidClick: true,
    });
  } else {
    // element-ui loading 服务调用方式
  loading = Loading.service({
    lock: true,
    text:'加载中',
    spinner: 'el-icon-loading', // 自定义图标
    background: 'rgba(0, 0, 0, 0.3)',
    // fullscreen: false
  })
  }
}

// loading结束 方法
function endLoading() {
  if(isMobile) {
    Toast.clear()
  } else {
    loading.close()
  }
}
let needLoadingRequestCount = 0
export function showFullScreenLoading() {
    if (needLoadingRequestCount === 0) {
        startLoading()
    }
    needLoadingRequestCount++
}

export function tryHideFullScreenLoading() {
    // if(isMobile) {
    //   if (needLoadingRequestCount <= 0) {
    //     needLoadingRequestCount--
    //   }
    // } else {
      if (needLoadingRequestCount > 0) {
        needLoadingRequestCount--
      }
    // }
    if (needLoadingRequestCount === 0) {
        endLoading()
    }
}
const service = axios.create({
  baseURL: url, // url = base url + request url
  timeout: 60000, // request timeout
})
const whiteUrl = [
  '/website/v1/verifyTencentImageCode',
  '/website/v1/getNftCommodityList',
  '/website/v1/sms/getCode',
  '/website/v1/sms/login',
]
service.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = 'zh-CN'
    if (store.state.userInfo.token && !whiteUrl.includes(config.url)) {
      config.headers['token'] = store.state.userInfo.token
    }
    showFullScreenLoading()
    return config
  },
  (error) => {
    // console.log(error) // for debug
    tryHideFullScreenLoading()
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  (response) => {
    tryHideFullScreenLoading()
    const res = response.data
    // console.log(res);
    // 如果自定义代码不是20000，则判断为错误.
    if (res.code !== '0000' && res.code !== 200) {
      if(res.code !== '1200') {
        if(isMobile) {
          Toast.fail(res.message);
        } else {
          Message({
            message: res.message,
            type: 'error',
            duration: 5 * 1000,
          })
        }
      }
      if (res.code ===  '1200') {
        if(router.currentRoute.path !== '/market') {
          router.push('/market')
        }
        store.commit('remTokenInfo')
        store.commit('setLoginShow', true)
      }
      if(res.code == 1916) {
        router.push('/market')
      }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  (error) => {
    tryHideFullScreenLoading()
    if(isMobile) {
      Toast.fail(res.message);
    } else {
      Message({
        message: error.message,
        type: 'error',
        duration: 5 * 1000,
      })
    }
    return Promise.reject(error)
  }
)

export default service
