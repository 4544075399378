<template>
  <div class="sports">
    <div class="sports-title">香港一铭体育</div>
    <img class="sports-logo" src="@/assets/pc/Bitmap@2x41.png" alt="">
    <div class="sports-text">
      一铭体育过去五年秉承专业、负责、认真的教学品质，凭着超过三十多年职业球员及教练的深厚经验，积极推动本港乒乓球专项运动，提升本地球员及青少年表现及水平。而是次送出的数字藏品贵宾卡正正代表一铭体育以及专注乒乓球运动的理念，这款数字藏品与Hunter共同发行的运动类型纪念数字藏品，数字藏品上有一铭的标志以及代表本会的乒乓球用品，而且会有会员的资料作为贵实认证。持有数字藏品的贵宾亲身报名指定课程及场地租赁更可获各项优惠。
    </div>
    <img class="sports-img" src="@/assets/pc/Bitmap@124x (1).png" alt="">
    <img class="sports-img2" src="@/assets/pc/Group 4@2x321.png" alt="">
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
  .sports {
    .sports-title {
      font-size: .45rem;
      font-weight: 900;
      margin-bottom: .48rem;
    }
    .sports-logo {
      width: 2.55rem;
      height: 2.43rem;
      margin-bottom: .23rem;
      margin-left: 2.05rem;
    }
    .sports-text {
      line-height: .27rem;
      width: 7.23rem;
      margin-bottom: .25rem;
    }
    .sports-img {
      width: 7.24rem;
      height: 4.83rem;
      margin-bottom: .25rem;
    }
    .sports-img2 {
      width: 7.24rem;
      height: 5.87rem;
    }
  }
</style>