<template>
  <div class="mobile-market m-navigation-bar">
    <div class="mobile-market-title-bg">
      <img src="@/assets/mobile/Group 7@122x.png" alt="" />
      <div class="mobile-market-title">数字藏品市场</div>
    </div>
    <div class="mobile-market-options" v-if="optionList.length !== 0">
      <van-tabs v-model="active" @click="onClick">
        <van-tab
          :title="item.name"
          v-for="(item, index) in optionList"
          :key="index"
        ></van-tab>
      </van-tabs>
    </div>
    <div class="noData" v-if="(optionList.length === 0 && list.length === 0) || list.length === 0">
      <img src="@/assets/mobile/noData.png" alt="">
      <div class="text">暂无上架的数字藏品</div>
    </div>
    <van-list v-model="loading" :offset="0" :finished="finished" :finished-text="list.length === 0 ? '' : '没有更多了'" @load="onLoad">
    <div class="mobile-home-img4">
      <div class="mobile-home-img4-content">
        <div
          class="mobile-home-img4-content-list"
          v-for="(item, index) in list"
          :key="index"
        >
          <img
            class="mobile-home-img4-content-list-img1"
            :src="item.nftPath"
            alt=""
          />
          <img
            class="mobile-home-img4-content-list-bg"
            src="@/assets/mobile/Rectangle@2x.png"
            alt=""
          />
          <div class="mobile-home-img4-content-list-searchIcon" @click="searchImgBtn(item)"></div>
          <div class="mobile-home-img4-content-list-type">
            <span>系列</span>
            <span style="overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;">{{ item.category }}</span>
          </div>
          <div class="mobile-home-img4-content-list-type2">
            <span>链</span>
            <div class="right">
              <img src="@/assets/mobile/SOLANA@2x.png" alt="" />
              <span>{{ item.chain }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    </van-list>
    <van-dialog v-model="show" confirmButtonText="关闭">
      <div class="search-img">
        <img :src="searchImg" />
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { marketList, getCategoryList } from '@/common/api'
import Utils from '@/common/utils.js'
export default {
  data() {
    return {
      searchImg: '',
      show: false,
      optionList: [],
      active: 0,
      list: [],
      loading: false,
      finished: false,
      offset:100,//滚动条与底部距离小于 offset 时触发load事件
      page: {
        page: 1,
        pageSize: 6,
      },
      category: this.$route.query.type,
    }
  },
  created() {
    this.getCategoryList()
    // this.marketList()
  },
  mounted(){
    var that = this;
    Utils.$on('Painting', function () {
      that.category = 'Painting'
      that.getCategoryList()
      that.page.page = 1
      that.getList('add')
    })
},
  methods: {
    async getList(add) {
      const res = await marketList({ page: this.page.page, pageSize: this.page.pageSize, category: this.category ? (this.category === 'All' ? [] : [this.category]) : [], })
      if(res.code == '0000') {
        if(add == 'add') {
          this.list = res.data.result
        } else {
          if(res.data.result.length !== 0) {
            this.list.push(...res.data.result);
            this.loading = false;
          } else {
            this.finished = true;
          }
        }
      } else {
        this.loading = false;
        this.finished = true;
      }
    },
    onLoad() {
      this.page.page++;
      this.getList();
    },
    searchImgBtn(item) {
      this.show = true
      this.searchImg = item.nftPath
    },
    async getCategoryList() {
      const res = await getCategoryList()
      this.optionList = [{name: 'All'}, ...res.data]
      this.optionList.forEach((item, index) => {
          if(this.category === item.name) {
            this.active = index
          }
        })
    },
    async onClick(id, name) {
      this.category = name
      this.page.page = 1
      this.getList('add');
    }
  },
}
</script>

<style lang="less" scoped>
.mobile-market {
  width: 100%;
  overflow: hidden;
  .mobile-market-title-bg {
    position: relative;
    width: 100%;
    height: 1.48rem;
    img {
      width: 5.97rem;
      height: 100%;
    }
    .mobile-market-title {
      position: absolute;
      top: 0.61rem;
      left: 0.45rem;
      font-size: 0.17rem;
      font-weight: 900;
    }
  }
  /deep/ .mobile-market-options {
    display: flex;
    align-items: center;
    height: 0.54rem;
    background-color: pink;
    color: #000;
    margin-bottom: .09rem;
    .van-tabs__nav--line {
      padding-bottom: 0;
      align-items: center;
      padding-left: 0.09rem;
    }
    .van-tabs--line .van-tabs__wrap {
      height: 100%;
    }
    .van-tabs {
      height: 100%;
      width: 100%;
    }
    .van-tabs__line {
      bottom: .11rem;
      background-color: #fff;
    }
    .van-tab--active {
      background: linear-gradient(180deg, #ff8787 0%, #ff3030 100%) !important;
      color: #fff !important;
      font-weight: 400 !important;
    }
    .van-tab {
      width: 1.31rem;
      height: 0.31rem;
      background: #ffffff;
      border-radius: 0.16rem;
      border: 0.01rem solid #e2dede;
      margin-right: 0.06rem;
      padding: 0;
      z-index: 2;
      font-size: 0.13rem;
      color: #000;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      box-sizing: border-box;
      padding-left: .1rem;
      padding-right: .1rem;
    }
    .van-tab__text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .search-img {
    width: 3rem;
    height: 3rem;
    padding: .2rem;
    padding-bottom: 0;
    box-sizing: border-box;
    padding-right: .1rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .noData {
    position: relative;
    width: 100%;
    height: 2.38rem;
    margin-bottom: .2rem;
    margin-top: .2rem;
    box-sizing: border-box;
    img {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      width: 1.87rem;
      height: 100%;
    }
    .text {
      position: absolute;
      bottom: .25rem;
      left: 50%;
      font-size: .09rem;
      color: #434343;
      transform: translate(-50%, 0);
    }
  }
}
</style>
