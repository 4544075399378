import { render, staticRenderFns } from "./home-daf.vue?vue&type=template&id=c890b3e8&scoped=true&"
import script from "./home-daf.vue?vue&type=script&lang=js&"
export * from "./home-daf.vue?vue&type=script&lang=js&"
import style0 from "./home-daf.vue?vue&type=style&index=0&id=c890b3e8&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c890b3e8",
  null
  
)

export default component.exports