<template>
  <div class="order-list pc-navigation-bar-height min-width">
    <div class="bx">
      <div class="order-list-head">
        <div class="esc" @click="escBtn">
          <i class="el-icon-arrow-left"></i>
          <div class="">返回商城</div>
        </div>
        <img class="logout" @click="dialogVisible = true" src="@/assets/pc/Oval 2@2x.png" alt="" />
        <img class="user-img" src="@/assets/pc/DOG11@2x.png" alt="" />
        <img class="user-img" src="@/assets/pc/DOG11@2x.png" alt="" />
        <div class="user-phone">
          {{ userInfo.name + '&nbsp;' + verifyPhone(userInfo.mobile) }}
        </div>
      </div>
      <div class="tabs">
        <div :class="['item', isActive === index ? 'isActive' : '']" @click="isActiveBtn(index)" v-for="(item, index) in tabsList" :key="index">
          {{ item.name }}
        </div>
      </div>
      <div class="noData" v-if="List.length === 0">
        <img src="@/assets/pc/Group 4 Copy.png" alt="">
        <div class="noData-text">暂无订单</div>
      </div>
      <div v-else class="order-content">
        <div class="order-content-list" v-for="item in List" :key="item.index">
          <div class="order_title">
        <p>
          {{ formatTime(parseInt(item.created) * 1000, 'YYYY-MM-DD hh:mm:ss') }}
        </p>
        <p class="serial">
          {{ $t('order.orderSerial') + '&nbsp;' + item.orderNo
          }}<img
            class="copy"
            src="@/assets/pc/CombinedShape@2x.png"
            alt=""
            @click="copyBtn(item)"
          />
        </p>
        <p
          :style="
            item.status === 'succeed'
              ? 'color:#52D132'
              : item.status === 'waiting'
              ? 'color:#FFD33A'
              : 'color: red'
          "
        >
          {{
            item.status === 'succeed'
              ? $t('order.havePaid')
              : item.status === 'waiting'
              ? $t('order.obligation')
              : $t('order.canceled')
          }}
        </p>
      </div>
      <el-divider></el-divider>
      <div class="order_content">
        <div class="content_left">
          <img :src="item.nft[0].image" alt="" />
          <div class="info">
            <p>{{ item.nft[0].code }}</p>
            <img v-if="item.nft[0].categoryImage" :src="item.nft[0].categoryImage" alt="">
            <p>
              {{ priceDispose(item.nft[0].price) + '&nbsp;' + item.nft[0].currency }}
            </p>
          </div>
        </div>
        <div class="content_right" v-if="item.status === 'waiting'">
          <div class="countDown">
            <div class="countDownText"
              >{{ $t('order.orderTime') }}</div
            >
            <!-- <count-down
              class="countDownTime"
              v-on:end_callback="countDownE_cb"
              :startTime="parseFloat(item.created) * 1000"
              :endTime="getDataTime(item.created)"
            ></count-down> -->
            <count-down class="countDownTime" v-on:end_callback="countDownE_cb" :startTime="Date.parse(new Date())" :endTime="getDataTime(item.created)" :dayTxt="':'" :hourTxt="':'" :minutesTxt="':'" :secondsTxt="''"></count-down>
          </div>
          <img src="@/assets/pc/buy.png" alt="" @click="PayBtn(item)" />
        </div>
      </div>
        </div>
      </div>
      <div class="pagination">
      <el-pagination
        :hide-on-single-page="page.total <= 1 ? true : false"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.page"
        :page-sizes="[4, 8, 10, 20]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.total"
      >
      </el-pagination>
    </div>
    </div>
    <el-dialog
  :title="$t('headerBox.outLogin')"
  :visible.sync="dialogVisible"
  :close-on-press-escape="false"
      :close-on-click-modal="false"
  top="15%"
  width="30%">
  <span>{{ $t('headerBox.outLoginOk') }}</span>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">{{ $t('headerBox.cancel') }}</el-button>
    <el-button type="primary" @click="okBtn">{{ $t('headerBox.Ok') }}</el-button>
  </span>
</el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { verifyPhone } from '@/common/common.js'
import { getOrderList } from '@/common/api'
import { priceDispose } from '@/common/common.js'
import CountDown from 'vue2-countdown'
export default {
  components: {
    CountDown,
  },
  data() {
    return {
      priceDispose,
      verifyPhone: verifyPhone,
      dialogVisible: false,
      tabsList: [
        {
          name: '全部',
        },
        {
          name: '待付款',
        },
        {
          name: '已取消',
        },
        {
          name: '已支付',
        },
      ],
      isActive: 0,
      page: {
        page: 1,
        pageSize: 4,
        total: 0,
      },
      List: [],
      Order: '0',
    }
  },
  computed: {
    ...mapState(['userInfo']),
  },
  created() {
    this.getOrderList()
  },
  methods: {
    ...mapMutations([ 'remTokenInfo']),
    getDataTime(time) {
      let data = parseInt(time) * 1000 + 605000
      return data
    },
    countDownE_cb() {
        return this.getOrderList()
    },
    async getOrderList() {
      const res = await getOrderList(
        this.Order,
        this.page.page,
        this.page.pageSize
      )
      this.page.total = res.data.total
      this.List = res.data.result
    },
    handleSizeChange(val) {
      this.page.pageSize = val
      this.getOrderList()
    },
    handleCurrentChange(val) {
      this.page.page = val
      this.getOrderList()
      window,scrollTo(0,0);
    },
    PayBtn(item) {
      this.$router.push(`pay?orderNo=${item.orderNo}`)
      window.scrollTo(99999, 0)
    },
    copyBtn(item) {
      var input = document.createElement('input')
      //将input的值设置为需要复制的内容
      input.value = item.orderNo
      //添加input标签
      document.body.appendChild(input)
      //选中input标签
      input.select()
      //执行复制
      document.execCommand('copy')
      //成功提示信息
      this.$message.success(this.$t('message.copyOk'))
      //移除input标签
      document.body.removeChild(input)
    },
    escBtn() {
      this.$router.push('/market')
    },
    isActiveBtn(index) {
      this.isActive = index
      this.Order = index + ''
      this.getOrderList()
    },
    okBtn() {
      this.remTokenInfo()
      this.dialogVisible = false
      this.$message.success(this.$t('message.escLoginOk'))
      this.$router.push('/market')
    },
  },
}
</script>

<style lang="less" scoped>
.order-list {
  .bx {
    width: 11.43rem;
    box-sizing: border-box;
    margin: 0 auto;
    .order-list-head {
      position: relative;
      width: 100%;
      box-sizing: border-box;
      height: 3.88rem;
      background: url('../../assets/pc/Group 17@2x.png') no-repeat;
      background-size: 100% 100%;
      .esc {
        cursor: pointer;
        position: absolute;
        left: 0.15rem;
        top: 0.48rem;
        width: 1.71rem;
        height: 0.67rem;
        box-sizing: border-box;
        background: rgba(0, 0, 0, 0.78);
        border-radius: 0.33rem;
        display: flex;
        font-size: 0.21rem;
        color: #fff;
        align-items: center;
        justify-content: center;
        /deep/ .el-icon-arrow-left {
          margin-top: 0.05rem;
          margin-right: 0.08rem;
        }
      }
      .logout {
        position: absolute;
        top: 0.48rem;
        right: 0.12rem;
        width: 0.67rem;
        height: 0.67rem;
        cursor: pointer;
      }
      .user-img {
        position: absolute;
        top: 1.01rem;
        left: 4.73rem;
        width: 1.88rem;
        height: 1.88rem;
      }
      .user-phone {
        font-size: 0.24rem;
        color: #282828;
        position: absolute;
        bottom: 0.44rem;
        left: 4.82rem;
      }
    }
    .tabs {
      background-color: #fff;
      height: 0.83rem;
      display: flex;
      align-items: center;
      font-size: 0.24rem;
      color: #7d7d7d;
      padding-left: .35rem;
      .item {
        margin-right: .69rem;
        cursor: pointer;
      }
      .isActive {
        width: 1.05rem;
        height: .37rem;
        background: linear-gradient(180deg, #FF8787 0%, #FF3030 100%);
        border-radius: .19rem;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: .39rem;
      }
    }
    .order-content {
      padding-bottom: .2rem;
      // height: 15.56rem;
      .order-content-list {
        position: relative;
        background-color: #fff;
        width: 100%;
        height: 3.69rem;
        box-sizing: border-box;
        margin-top: .2rem;
        padding: .27rem .81rem .29rem .36rem;
        .order_title {
      color: #939393;
      font-size: .2rem;
      display: flex;
      justify-content: space-between;
      .serial {
        // margin-left: -100px;
        .copy {
          width: .19rem;
          height: .19rem;
          margin-left: .23rem;
          cursor: pointer;
        }
      }
    }
    .el-divider--horizontal {
      background-color: #d4d4d4;
      margin-top: .19rem;
      margin-bottom: .35rem;
      height: .02rem;
    }
    .order_content {
      display: flex;
      justify-content: space-between;
      .content_left {
        display: flex;
        img {
          width: 2.24rem;
          height: 2.24rem;
          margin-right: .11rem;
        }
        .info {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          img {
            margin-top: .07rem;
            margin-bottom: .39rem;
            width: 2.21rem;
            height: .53rem;
          }
          p {
            height: .42rem;
            color: #2A2A2A;
            font-size: .4rem;
          }
        }
      }
      .content_right {
        padding-top: .05rem;
        position: relative;
        // width: 281px;
        // height: 147px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        img {
          width: 2.28rem;
          height: .84rem;
          cursor: pointer;
          position: absolute;
          bottom: 0rem;
          right: -0.35rem;
        }
        .countDown {
          // position: absolute;
          // left: 35px;
          // top: 0;
          // right: 0;
          // width: 225px;
          // display: flex;
          // align-items: center;
          font-size: 20px;
          color: #2A2A2A;
          // margin-bottom: 5px;
          font-size: .18rem;
          .countDownText {
            margin-bottom: .05rem;
          }
          .countDownTime {
            text-align: right;
          }
        }
      }
    }
      }
    }
    .noData {
      position: relative;
      padding: .6rem 0;
      text-align: center;
      img {
        width: 3.93rem;
        height: 5.07rem;
      }
      .noData-text {
        font-size: .16rem;
        color: #434343;
        position: absolute;
        bottom: 1.2rem;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
    .pagination {
      text-align: center;
      margin-bottom: .4rem;
    }
  }
}
</style>
