export function checkPhone(value){ 
  var phone = /^[0-9]+$/
  if(!phone.test(value)){ 
      return false; 
  }
  if(value.length > 20) {
    return false; 
  }
  return true
}
// 处理商品信息返回的attr字段
export function newAttr(a) {
  let str = a.split('&')
  let b = []
  let obj = { head: '', body: '', }
  let newArr = []
  str.forEach(item => {
    b = item.split('=')
    obj.head = b[0]
    obj.body = b[1]
    newArr.push(obj)
    obj = { head: '', body: '', }
  })
  return newArr
  }
export const verifyPhone = (value) => {
  if(value.length == 5) {
    return value.replace(/(\d{1})\d{4}(\d{0})/, '$1****$2');
  }
  if(value.length == 6) {
    return value.replace(/(\d{1})\d{4}(\d{1})/, '$1****$2');
  }
  if(value.length == 7) {
    return value.replace(/(\d{1})\d{4}(\d{2})/, '$1****$2');
  }
  if(value.length == 8) {
    return value.replace(/(\d{2})\d{4}(\d{2})/, '$1****$2');
  }
  if(value.length == 9) {
    return value.replace(/(\d{2})\d{4}(\d{3})/, '$1****$2');
  }
  if(value.length == 10) {
    return value.replace(/(\d{3})\d{4}(\d{3})/, '$1****$2');
  }
  if(value.length > 10) {
    let arr = value.split('')
    arr.push('a')
    let behind = arr.slice(-5, -1)
    let front = arr.slice(0, -5)
    let newFront = front.slice(0, -4)
    return newFront.join('') + '****' + behind.join('');
  }
}

export const priceDispose = (s, type) => {
  if (/[^0-9\.]/.test(s)) 
  return "0"; 
  if (s == null || s == "") 
  return "0"; 
  s = s.toString().replace(/^(\d*)$/, "$1."); 
  s = (s + "00").replace(/(\d*\.\d\d)\d*/, "$1"); 
  s = s.replace(".", ","); 
  var re = /(\d)(\d{3},)/; 
  while (re.test(s)) 
  s = s.replace(re, "$1,$2"); 
  s = s.replace(/,(\d\d)$/, ".$1"); 
  if (type == 0) {// 不带小数位(默认是有小数位) 
  var a = s.split("."); 
  if (a[1] == "00") { 
  s = a[0]; 
  } 
  } 
  return s;
}

// 银行卡验证
export var validateCreditCard = (rule, s, callback) => {
  // remove non-numerics
  var v = '0123456789',
    w = '',
    i,
    j,
    k,
    m,
    c,
    a,
    x

  for (i = 0; i < s.length; i++) {
    x = s.charAt(i)

    if (v.indexOf(x, 0) !== -1) {
      w += x
    }
  }
  // validate number
  j = w.length / 2
  k = Math.floor(j)
  m = Math.ceil(j) - k
  c = 0

  for (i = 0; i < k; i++) {
    a = w.charAt(i * 2 + m) * 2
    c += a > 9 ? Math.floor(a / 10 + (a % 10)) : a
  }

  for (i = 0; i < k + m; i++) {
    c += w.charAt(i * 2 + 1 - m) * 1
  }

  var newNumber = c % 10 === 0
  // if(s === '') {
  //   callback(new Error('請輸入银行卡号'))
  // }
  if(!newNumber) {
    callback(new Error(store.state.language === '请输入正确的银行卡号'))
  }
  callback()
}