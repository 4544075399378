<template>
  <div>
    <pc-navigation-bar v-if="!$isMobile"></pc-navigation-bar>
    <m-navigation-bar v-else></m-navigation-bar>
      <p-case v-if="!$isMobile"></p-case>
      <m-case v-else></m-case>
      <pc-footer v-if="!$isMobile"></pc-footer>
    <m-footer v-else></m-footer>
  </div>
</template>

<script>
import PCase from '@/components/pc/case.vue'
import MCase from '@/components/mobile/case.vue'
export default {
  components: {
    PCase,
    MCase
  }
}
</script>

<style>

</style>