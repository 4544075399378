<template>
  <div  class="gallery">
    <div class="gallery-title">天趣艺术画廊</div>
    <img class="gallery-img2" src="@/assets/pc/Bitmap@2x123 (2).png" alt="">
    <div class="gallery-text1">
      天趣国际艺术携手印尼驻香港总领事馆，及香港印尼研究学社协同合作，为庆祝印尼蜡染日共同呈现“向世界遗产致敬：印尼艺术展”。<br>
蜡染被称为印尼织物，是印尼的国宝和世界非物质文化遗产。对于每个印尼人来说，在蜡染节这一天穿上最好的蜡染服装，是对包括蜡染在内的印尼传统艺术的最高敬意。展览即展出世界非遗蜡染服饰及充满印尼生活风情的艺术作品，展览时间为9月30日至11月11日，期间将举行具印尼特色的蜡染及印尼咖啡工作坊。<br>
印尼现今人口达2.8亿，近年来经济发展势头强劲，发展成果显著，国际地位日益重要。作为2022年G20轮值主席国和2023年东盟轮值主席国，印尼在世界政治和经济舞台上的表现将更加耀眼夺目。本次展览在这历史大背景下举办更显举足轻重、意义非凡！展览将带领大家感受印尼悠久的世界非遗文化、原汁原味的民族风情和独具特色的匠心精神。本次展览谨在通过向观众展示印尼丰富多彩的历史及艺术文化来推动印尼与香港文化艺术的深入交流，打造一个融合世界非物质文化遗产与当代艺术的国际性文化交流平台，培育浓厚且多元的文化艺术氛围。<br>
本次大会，Hunter作为合作方，将具有印尼独特民族风情的艺术作品生成数字藏品，并在ATM 上架发售，为用户带来不一样的购买体验。
</div>
    <img class="gallery-img3" src="@/assets/pc/Bitmap@234x (3).png" alt="">
  </div>
</template>

<script>
export default {
  name: 'gallery',
}
</script>

<style lang="less" scoped>
  .gallery {
    .gallery-title {
      font-size: .45rem;
      font-weight: 900;
      margin-bottom: .25rem;
    }
    .gallery-img1 {
      width: 2.72rem;
      height: .64rem;
      margin-bottom: .17rem;
    }
    .gallery-text1 {
      width: 7.23rem;
      line-height: .27rem;
      margin-bottom: .48rem;
    }
    .gallery-img2 {
      width: 7.51rem;
      height: 10.71rem;
      margin-bottom: .36rem;
    }
    .gallery-img3 {
      width: 7.51rem;
      height: 5.63rem;
    }
  }
</style>