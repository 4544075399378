<template>
  <el-dialog
    :title="title"
    center
    style="font-size: 18px;"
    :visible.sync="isShowDialog"
    width="50%"
    @close="closed"
    :before-close="closed"
    top="1.5%"
    :append-to-body="true"
  >
    <div v-if="title === $t('loginBox.userAgreement')" class="newContent">
      {{ $t('userAgreement.title') }}
      <br />
      {{ $t('userAgreement.userAgreementText1') }}
      {{ $t('userAgreement.userAgreementText2') }}
      <br />
      {{ $t('userAgreement.userAgreementText3') }}
      <br />
      {{ $t('userAgreement.userAgreementText4') }}
      <br />
      {{ $t('userAgreement.userAgreementText5') }}
      <br />
      {{ $t('userAgreement.userAgreementText6') }}
      <br />{{ $t('userAgreement.userAgreementText7') }}<br />
      {{ $t('userAgreement.userAgreementText8') }}
      <br />
      {{ $t('userAgreement.userAgreementText9') }}
      <br />
      {{ $t('userAgreement.userAgreementText10') }}
      <br />{{ $t('userAgreement.userAgreementText11') }}<br />
      {{ $t('userAgreement.userAgreementText12') }}
      <br />
      {{ $t('userAgreement.userAgreementText13') }}
      <br />
      {{ $t('userAgreement.userAgreementText14') }}
      <br />
      {{ $t('userAgreement.userAgreementText15') }}
      <br />
      {{ $t('userAgreement.userAgreementText16') }}
      <br />
      {{ $t('userAgreement.userAgreementText17') }}
      <br />
      {{ $t('userAgreement.userAgreementText18') }}
      <br />
      {{ $t('userAgreement.userAgreementText19') }}
      <br />
      {{ $t('userAgreement.userAgreementText20') }}
      <br />
      {{ $t('userAgreement.userAgreementText21') }}
      <br />
      {{ $t('userAgreement.userAgreementText22') }}
      <br />
      {{ $t('userAgreement.userAgreementText23') }}
      <br />
      {{ $t('userAgreement.userAgreementText24') }}
      <br />
      {{ $t('userAgreement.userAgreementText25') }}
      <br />
      {{ $t('userAgreement.userAgreementText26') }}
      <br />
      {{ $t('userAgreement.userAgreementText27') }}
      <br />
      {{ $t('userAgreement.userAgreementText28') }}
      <br />
      {{ $t('userAgreement.userAgreementText29') }}
      <br />
      {{ $t('userAgreement.userAgreementText30') }}
      <br />
      {{ $t('userAgreement.userAgreementText31') }}
      <br />
      {{ $t('userAgreement.userAgreementText32') }}
      <br />
      {{ $t('userAgreement.userAgreementText33') }}
      <br />
      {{ $t('userAgreement.userAgreementText34') }}
      <br />
      {{ $t('userAgreement.userAgreementText35') }}
      <br />
      {{ $t('userAgreement.userAgreementText36') }}
      <br />
      {{ $t('userAgreement.userAgreementText37') }}
      <br />
      {{ $t('userAgreement.userAgreementText38') }}
      <br />
      {{ $t('userAgreement.userAgreementText39') }}
      <br />
      {{ $t('userAgreement.userAgreementText40') }}
      <br />
      {{ $t('userAgreement.userAgreementText41') }}
      <br />
      {{ $t('userAgreement.userAgreementText42') }}
      <br />
      {{ $t('userAgreement.userAgreementText43') }}
      <br />
      {{ $t('userAgreement.userAgreementText44') }}
      <br />
      {{ $t('userAgreement.userAgreementText45') }}
      <br />
      {{ $t('userAgreement.userAgreementText46') }}
      <br />
      {{ $t('userAgreement.userAgreementText47') }}
      <br />
      {{ $t('userAgreement.userAgreementText48') }}
      <br />
      {{ $t('userAgreement.userAgreementText49') }}
      <br />
      {{ $t('userAgreement.userAgreementText50') }}
      <br />
      {{ $t('userAgreement.userAgreementText51') }}
      <br />
      {{ $t('userAgreement.userAgreementText52') }}
      <br />
      {{ $t('userAgreement.userAgreementText53') }}
      <br />
      {{ $t('userAgreement.userAgreementText54') }}
      <br />
      {{ $t('userAgreement.userAgreementText55') }}
      <br />
      {{ $t('userAgreement.userAgreementText56') }}
      <br />
      {{ $t('userAgreement.userAgreementText57') }}
      <br />
      {{ $t('userAgreement.userAgreementText58') }}
      <br />
      {{ $t('userAgreement.userAgreementText59') }}
      <br />
      {{ $t('userAgreement.userAgreementText60') }}
      <br />
      {{ $t('userAgreement.userAgreementText61') }}
      <br />
      {{ $t('userAgreement.userAgreementText62') }}
      <br />
      {{ $t('userAgreement.userAgreementText63') }}
      <br />
      {{ $t('userAgreement.userAgreementText64') }}
      <br />
      {{ $t('userAgreement.userAgreementText65') }}
      <br />
      {{ $t('userAgreement.userAgreementText66') }}
      <br />
      {{ $t('userAgreement.userAgreementText67') }}
      <br />
      {{ $t('userAgreement.userAgreementText68') }}
      <br />
      {{ $t('userAgreement.userAgreementText69') }}
      <br />
      {{ $t('userAgreement.userAgreementText70') }}
      <br />
      {{ $t('userAgreement.userAgreementText71') }}
      <br />
      {{ $t('userAgreement.userAgreementText72') }}
      <br />
      {{ $t('userAgreement.userAgreementText73') }}
      <br />
      {{ $t('userAgreement.userAgreementText74') }}
      <br />
      {{ $t('userAgreement.userAgreementText75') }}
      <br />
      {{ $t('userAgreement.userAgreementText76') }}
      <br />
      {{ $t('userAgreement.userAgreementText77') }}
      <br />
      {{ $t('userAgreement.userAgreementText78') }}
      <br />
      {{ $t('userAgreement.userAgreementText79') }}
      <br />
      {{ $t('userAgreement.userAgreementText80') }}
      <br />
      {{ $t('userAgreement.userAgreementText81') }}
      <br />
      {{ $t('userAgreement.userAgreementText82') }}
      <br />
      {{ $t('userAgreement.userAgreementText83') }}
      <br />
      {{ $t('userAgreement.userAgreementText84') }}
      <br />
      {{ $t('userAgreement.userAgreementText85') }}
      <br />
      {{ $t('userAgreement.userAgreementText86') }}
      <br />
      {{ $t('userAgreement.userAgreementText87') }}
      <br />
      {{ $t('userAgreement.userAgreementText88') }}
      <br />
      {{ $t('userAgreement.userAgreementText89') }}
      <br />
      {{ $t('userAgreement.userAgreementText90') }}
      <br />
      {{ $t('userAgreement.userAgreementText91') }}
    </div>
    <div v-if="title === $t('loginBox.privacyPolicy')" class="newContent">
      {{ $t('privacyPolicy.privacyPolicyText1') }}
      <br />
      2022-6-27 02:55:51
      <br />
      {{ $t('privacyPolicy.privacyPolicyText2') }}
      <br />
      <p>{{ $t('privacyPolicy.privacyPolicyText3') }}</p>
      {{ $t('privacyPolicy.privacyPolicyText4') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText5') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText6') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText7') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText8') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText9') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText10') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText11') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText12') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText13') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText14') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText15') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText16') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText17') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText18') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText19') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText20') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText21') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText22') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText23') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText24') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText25') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText26') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText27') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText28') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText29') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText30') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText31') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText32') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText33') }}
      
      <br />
      {{ $t('privacyPolicy.privacyPolicyText34') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText35') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText36') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText37') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText38') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText39') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText40') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText41') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText42') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText43') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText44') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText45') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText46') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText47') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText48') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText49') }}
      
      <br />
      {{ $t('privacyPolicy.privacyPolicyText50') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText51') }}
      
      <br />
      {{ $t('privacyPolicy.privacyPolicyText52') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText53') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText54') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText55') }}
      
      <br />
      {{ $t('privacyPolicy.privacyPolicyText56') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText57') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText58') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText59') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText60') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText61') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText62') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText63') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText64') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText65') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText66') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText67') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText68') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText69') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText70') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText71') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText72') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText73') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText74') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText75') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText76') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText77') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText78') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText79') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText80') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText81') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText82') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText83') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText84') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText85') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText86') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText87') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText88') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText89') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText90') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText91') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText92') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText93') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText94') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText95') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText96') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText97') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText98') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText99') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText100') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText101') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText102') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText103') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText104') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText105') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText106') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText107') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText108') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText109') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText110') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText111') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText112') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText113') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText114') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText115') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText116') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText117') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText118') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText119') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText120') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText121') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText122') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText123') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText124') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText125') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText126') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText127') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText128') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText129') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText130') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText131') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText132') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText133') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText134') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText135') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText136') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText137') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText138') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText139') }}
      <br />
      {{ $t('privacyPolicy.privacyPolicyText140') }}
    </div>
    <el-row type="flex" justify="center" slot="footer">
      <el-button @click="closed">{{ $t('privacyPolicy.cancel') }}</el-button>
      <el-button @click="closed" type="primary">{{ $t('privacyPolicy.haveRead') }}</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
export default {
  name: 'newDialog',
  props: {
    isShowDialog: {
      default: false,
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
    },
  },
  created() {
  },
  methods: {
    closed() {
      this.$emit('showDialog')
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .el-dialog__title {
  font-weight: 600;
  font-size: 20px;
}
.newContent {
  width: 100%;
  height: 550px;
  padding: 0 40px;
  white-space: pre-wrap;
  overflow: auto;
  box-sizing: border-box;
  line-height: 26px;
  font-size: 14.5px;
  p {
    padding: 10px 0 20px 0;
    font-size: 18px;
    text-align: center;
  }
}
.newContent::-webkit-scrollbar {
  width: 0 !important;
}
</style>
